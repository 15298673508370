import React, { useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import useLogout from "../../../hooks/useLogout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import { profileStart } from "../../../store/slices/AdminSlice";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { setLanguage } from "react-multi-lang";
import { useTranslation } from "react-multi-lang";

const AdminHeader = () => {
  const t = useTranslation("admin_header")
  const navigate = useNavigate();
  const { logout } = useLogout();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const location = useLocation();

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      background: "linear-gradient(89.12deg, #3D9F99 0.53%, #1d9bb3 96.47%)!important",
      border: "none!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "40px",
      cursor:"pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#000!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#000!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#000",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const [languageOption, setLanguageOption] = React.useState(null);

  const options = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "jp", label: "Japanese" },
  ]

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      const selectedOption = options.find(option => option.value === lang);
      setLanguageOption(selectedOption);
      setLanguage(selectedOption.value);
    } else {
      setLanguage(options[0].value);
      setLanguageOption(options[0]);
    }
  }, [])

  return (
    <div>
      <div className="admin-ui-header-sec">
        <Navbar expand="lg">
          <Container fluid>
            <div className="admin-ui-title-sec">
              <div
                className="admin-ui-collapse-btn"
                onClick={() => dispatch(toggleSidebar())}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#3D9F99"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
                </svg> */}
                <svg width="22"
                  height="22"
                  fill="#3D9F99" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0v8h1v-8h-1zm6 0v8h2v-8h-2zm-1 2l-2 2 2 2v-4z" />
                </svg>
              </div>
              <h2>{location.pathname=="/" && "Dashboard"}</h2>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              {/* <Image
                className="hamburger-menu"
                src={window.location.origin + "/images/hamburger-menu.png"}
                type="image/png"
              /> */}
               <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fillRule="evenodd"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 1707 1707"
              >
                <path
                  fill="#3D9F99"
                  d="M1453 1698H254c-140 0-254-114-254-253V262C0 122 114 9 254 9h1199c140 0 253 113 253 253v1183c0 139-113 253-253 253z"
                  data-original="#6c6c6c"
                ></path>
                <path
                  fill="#fff"
                  d="M1295 536H412c-76 0-137-61-137-137 0-75 61-136 137-136h883c75 0 136 61 136 136 0 76-61 137-136 137zm-139 454H551c-76 0-137-61-137-137 0-75 61-136 137-136h605c76 0 137 61 137 136 0 76-62 137-137 137zm139 454H412c-76 0-137-61-137-137 0-75 61-136 137-136h883c75 0 136 61 136 136 0 76-61 137-136 137z"
                  data-original="#ffffff"
                ></path>
              </svg>
            </Navbar.Toggle>
            {profile.loading ? (
              <Row>
                <Col>
                  <Skeleton circle width={50} height={50} />
                </Col>
                <Col>
                  <Skeleton width={100} height={50} />
                </Col>
              </Row>
            ) : Object.keys(profile.data).length > 0 ? (
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <div className="user-dropdown-sec">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <div className="admin-user-details">
                          <CustomLazyLoad
                            className="admin-user-img"
                            src={profile.data.picture}
                          />
                          <span>{profile.data.name}</span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {/* <Dropdown.Item
                          onClick={() => navigate("/account-settings")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                            <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                          </svg>
                          <span>Settings</span>
                        </Dropdown.Item> */}
                        {profile.data.kyc_status == 3 && <Dropdown.Item
                          onClick={() => navigate("/account-settings")}
                        >
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                          </svg> */}
                          <svg width="24"
                            height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" fill=""></path>
                            <path d="M12 35.6309C12 34.5972 12.772 33.7241 13.7995 33.6103C21.515 32.7559 26.5206 32.8325 34.218 33.6287C35.2324 33.7337 36 34.5918 36 35.6116C36 36.1807 35.7551 36.7275 35.3262 37.1014C26.2414 45.0195 21.0488 44.9103 12.6402 37.1087C12.2306 36.7286 12 36.1897 12 35.6309Z" fill=""></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.1151 34.6234C26.4784 33.8334 21.5449 33.7587 13.9095 34.6042C13.3954 34.6612 13 35.1002 13 35.6309C13 35.9171 13.1187 36.1885 13.3204 36.3757C17.4879 40.2423 20.6461 41.9887 23.7333 41.9999C26.8309 42.0113 30.1592 40.2783 34.6691 36.3476C34.8767 36.1667 35 35.8964 35 35.6116C35 35.0998 34.6154 34.6752 34.1151 34.6234ZM13.6894 32.6164C21.4852 31.7531 26.5628 31.8315 34.3209 32.6341C35.8495 32.7922 37 34.0838 37 35.6116C37 36.465 36.6336 37.2884 35.9832 37.8553C31.4083 41.8426 27.598 44.0141 23.726 43.9999C19.8435 43.9857 16.2011 41.7767 11.9601 37.8418C11.3425 37.2688 11 36.4624 11 35.6309C11 34.0943 12.1487 32.787 13.6894 32.6164Z" fill=""></path>
                            <path d="M32 20C32 24.4183 28.4183 28 24 28C19.5817 28 16 24.4183 16 20C16 15.5817 19.5817 12 24 12C28.4183 12 32 15.5817 32 20Z" fill=""></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 26C27.3137 26 30 23.3137 30 20C30 16.6863 27.3137 14 24 14C20.6863 14 18 16.6863 18 20C18 23.3137 20.6863 26 24 26ZM24 28C28.4183 28 32 24.4183 32 20C32 15.5817 28.4183 12 24 12C19.5817 12 16 15.5817 16 20C16 24.4183 19.5817 28 24 28Z" fill=""></path>
                          </svg>
                          <span>{t("heading")}</span>
                        </Dropdown.Item>}
                        <Dropdown.Item onClick={logout}>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#6E6D8E"
                              fillRule="evenodd"
                              d="M5.636 5.636C8.582 2.691 13.059 2.215 16.5 4.204a1 1 0 01-1 1.731 7 7 0 100 12.13 1 1 0 011 1.731c-3.441 1.99-7.918 1.514-10.864-1.432a9 9 0 010-12.728zm12.657 2.657a1 1 0 011.414 0l1.891 1.891c.179.179.353.352.488.512.148.175.308.396.402.686a2 2 0 010 1.236 2.02 2.02 0 01-.402.687 9.18 9.18 0 01-.488.511l-1.89 1.891a1 1 0 01-1.415-1.414L19.586 13H12a1 1 0 110-2h7.586l-1.293-1.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><title>ionicons-v5-o</title><path d="M160,256a16,16,0,0,1,16-16H320V136c0-32-33.79-56-64-56H104a56.06,56.06,0,0,0-56,56V376a56.06,56.06,0,0,0,56,56H264a56.06,56.06,0,0,0,56-56V272H176A16,16,0,0,1,160,256Z"></path><path d="M459.31,244.69l-80-80a16,16,0,0,0-22.62,22.62L409.37,240H320v32h89.37l-52.68,52.69a16,16,0,1,0,22.62,22.62l80-80a16,16,0,0,0,0-22.62Z"></path></svg>
                          <span>{t("logout")}</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Nav>
              </Navbar.Collapse>
            ) : null}
            {/* <div className="language-select">
              <Select
                options={options}
                styles={customStyles}
                isSearchable={false}
                value={languageOption}
                placeholder={<div>Language</div>}
                onChange={(selectedOption) => {
                  setLanguageOption(selectedOption);
                  setLanguage(selectedOption.value);
                  localStorage.setItem("lang", selectedOption.value);
                }}
              />
            </div> */}
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default AdminHeader;
