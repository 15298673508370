import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import CollapseSidebar from "./CollapseSidebar";
import { useDispatch, useSelector } from "react-redux";
import { profileStart } from "../../../store/slices/AdminSlice";

export default function AdminSidebar() {
  const theme = useSelector((state) => state.admin.theme);
  const sidebar = useSelector((state) => state.common.sidebar);



  return sidebar ? <CollapseSidebar/> : <Sidebar />;
}
