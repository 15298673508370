import React from "react";
import { Image } from "react-bootstrap";

const NoDataFound = () => {
  return (
    <div className="no-data-found-sec">
      <Image
        className="no-bot-exists-img"
        src={window.location.origin + "/img/no-data-found.svg"}
      />
    </div>
  );
};

export default NoDataFound;
