import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";
import { transactionStatusStart } from "../../store/slices/PaymentSlice";
import { useTranslation } from "react-multi-lang";

const TransactionStatusUpdateModal = (props) => {

  const t = useTranslation("transactions.status_update")
  const dispatch = useDispatch();
  const transactionStatus = useSelector((state) => state.payment.transactionStatus);
  const [skipRender, setSkipRender] = useState(true);

  const acceptSchema = Yup.object().shape({
    payment_id: Yup.string()
      .required("Required"),
  });

  const rejectSchema = Yup.object().shape({
    cancel_reason: Yup.string()
      .required("Cancel reason is required"),
  });

  const handleSubmit = (values) => {
    dispatch(transactionStatusStart(values));
  };

  useEffect(() => {
    if (!skipRender && !transactionStatus.loading && Object.keys(transactionStatus.data).length > 0) {
      props.closeAcceptPayoutPaymentModal();
    }
    setSkipRender(false);
  }, [transactionStatus]);

  const getFieldLabel = (type) => {
    switch (type) {
      case "upi":
        return "UTR Number";
      case "card":
        return "Payment ID";
      case "crypto":
        return "Transaction Hash";
      default:
        return "Payment ID";
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.acceptPayoutModal}
        onHide={props.closeAcceptPayoutPaymentModal}
        backdrop="static"
      >
        <Modal.Body>
          <Button
            onClick={() => props.closeAcceptPayoutPaymentModal()}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="request-payout-sec">
            <h3>{props.acceptPayoutModal.request == 2 ? "Accept" : "Decline"} {t("payout")}</h3>
            <Formik
              initialValues={props.acceptPayoutModal.request == 2 ?{
                transaction_id: props.acceptPayoutModal.id,
                status: 2,
                payment_id: "",
              }:{
                transaction_id: props.acceptPayoutModal.id,
                status: 3,
                cancel_reason: ""
              }}
              validationSchema={props.acceptPayoutModal.request == 2 ? acceptSchema : rejectSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, errors }) => (
                <FORM className="bank-account-form">
                  {props.acceptPayoutModal.request == 2 ? <React.Fragment>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{getFieldLabel(props.acceptPayoutModal.payment_type)}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={`Enter ${getFieldLabel(props.acceptPayoutModal.payment_type)}`}
                        name="payment_id"
                        type="text"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="payment_id"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                  </React.Fragment> : <React.Fragment>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>{t("cancel_reason")} *</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("enter_cancel_reason")}
                        name="cancel_reason"
                        type="text"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="cancel_reason"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                  </React.Fragment>}
                  <div className="request-payout-payment-method-btn-sec">
                    <Button
                      className="efi-cancel-btn"
                      onClick={() => props.closeAcceptPayoutPaymentModal()}
                    >
                     {t("cancel")}
                    </Button>
                    <Button type="submit" className="efi-auth-btn" disabled={transactionStatus.buttonDisable}>{transactionStatus.buttonDisable ? <ButtonLoader/> : t("submit")}</Button>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionStatusUpdateModal;
