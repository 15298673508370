import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { transactionListStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound"
import { useTranslation } from "react-multi-lang";

const RecentTransaction = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("dashboard.recent_transaction");
  const profile = useSelector((state) => state.admin.profile);
  const transactionList = useSelector((state) => state.payment.transactionList);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        transactionListStart({
          skip: 0,
          take: 6,
        })
      );
    }
  }, [profile]);

  const getStatusClass = (status) => {
    switch (status) {
      case "Initiated":
        return "payment-initiated";
      case "Inprogress":
        return "payment-onhold";
      case "Success":
        return "payment-paid";
      case "Failed":
        return "payment-rejected";
      case "Cancelled":
        return "payment-cancelled";
      case "Chargeback":
        return "payment-failed";
      case "Refunded":
        return "payment-initiated";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Initiated":
        return "#5C9EEB";
      case "Inprogress":
        return "#FFCE22";
      case "Success":
        return "#197E23";
      case "Failed":
        return "#ff0000";
      case "Cancelled":
        return "#FF8A00";
      case "Chargeback":
        return "#FF6A6A";
      case "Refunded":
        return "#5C9EEB";
      default:
        return "#5C9EEB";
    }
  };


  return (
    <>
      <div className="recent-transaction-sec">
        <h3>Recent Transactions</h3>
        <div className="recent-transaction-table-sec table-responsive">
          {transactionList.loading ? (
            [...Array(3)].map((i, key) => <Skeleton key={key} height={50} />)
          ) : Object.keys(transactionList.data).length > 0 ? (
            <React.Fragment>
              <Table>
                <thead>
                  <tr>
                    <th>{t("sl_no")}</th>
                    <th>{t("reference_id")}</th>
                    <th>{t("order_id")}</th>
                    <th>{t("total")}</th>
                    <th>{t("mode")}</th>
                    <th>{t("created_date")}</th>
                    <th>{t("status")}</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionList?.data?.response?.transactions?.length > 0 ? (
                    transactionList.data?.response?.transactions.slice(0,6).map((transaction, i) => (
                      <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{transaction.reference_id}</td>
                            <td>{transaction.order_id}</td>
                            <td>{transaction.amount} {transaction.currency_symbol}</td>
                            <td>{transaction.mode}</td>
                            <td>{transaction.created}</td>
                            <td>
                              <div
                                className={getStatusClass(transaction.status)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill={getStatusColor(transaction.status)}
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                                </svg>
                                {transaction.status}
                              </div>
                            </td>
                          </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        <NoDataFound />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          ) : null}
          <div></div>
        </div>
      </div>
    </>
  );
};

export default RecentTransaction;
