import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteProfileStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";
import useLogout from "../../hooks/useLogout";
import { useTranslation } from "react-multi-lang";

const DeleteAccountModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("delete_account");
  const deleteProfile = useSelector((state) => state.admin.deleteProfile);
  const [skipRender, setSkipRender] = useState(true);
  const {logout} = useLogout();

  const validationSchema = Yup.object().shape({
    password: Yup.string().matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid"))
      .required(t("password.required"))
  });

  const handleSubmit = (values) => {
    dispatch(deleteProfileStart(values))
  };

  useEffect(()=> {
    if(!skipRender && !deleteProfile.loading && Object.keys(deleteProfile.data).length > 0)
    {
      props.closeDeleteAccountModal();
      logout();
    }

    setSkipRender(false);
  }, [deleteProfile])


  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.deleteAccount}
        onHide={props.closeDeleteAccountModal}
      >
        <Modal.Body>
          <Button
            onClick={props.closeDeleteAccountModal}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
          <div className="settlement-top-sec">
            <h3>{t("heading")}</h3>
          </div>
          <Row className="align-items-center">
            <Col md={12}>
                <div className="bank-account-form-sec">
                <Formik
                    initialValues={{
                      password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <FORM className="bank-account-form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("password.label")}</Form.Label>
                          <Field
                            name="password"
                            className="form-control"
                            type="password"
                            placeholder={t("password.placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <div className="request-payout-payment-method-btn-sec">
                          <Button className="efi-cancel-btn" onClick={()=> {setFieldValue("password", "")}}>
                           {t("reset")}
                          </Button>
                          <Button className="efi-auth-btn" disabled={deleteProfile.buttonDisable} type="submit">{deleteProfile.buttonDisable ? <ButtonLoader/>: t("delete_btn.text")}</Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
            </Col>
          </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteAccountModal;
