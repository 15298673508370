import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    theme: "",
    login: getCommonInitialState(),
    register: getCommonInitialState(),
    forgotPassword: getCommonInitialState(),
    resetPassword: getCommonInitialState(),
    profile: getCommonInitialState(),
    updateProfile: getCommonInitialState(),
    dashboard: getCommonInitialState(),
    generateAPIKey: getCommonInitialState(),
    viewAPIKey: getCommonInitialState(),
    kycApplicant: getCommonInitialState(),
    kycStatusUpdate: getCommonInitialState(),
    changePassword: getCommonInitialState(),
    verifyForgotPassword: getCommonInitialState(),
    deleteProfile: getCommonInitialState(),
    emailVerify: getCommonInitialState(),
    updateTwoStep: getCommonInitialState(),
    twoStepAuth: getCommonInitialState(),
    resendOtp: getCommonInitialState(),
    dashboardChart: getCommonInitialState(),
    updateEmailCode: getCommonInitialState(),
    updateEmail: getCommonInitialState(),
    apiKeyView: getCommonInitialState(),
    apiKeyRegenerate: getCommonInitialState(),
    saltKeyView: getCommonInitialState(),
};

const AdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {

        //set theme
        setTheme: (state, action) => { state.theme = action.payload },

        //Login
        loginStart: (state, action) => { state.login = getCommonStart() },
        loginSuccess: (state, action) => { state.login = getCommonSuccess(action.payload) },
        loginFailure: (state, action) => { state.login = getCommonFailure(action.payload) },

        //Register
        registerStart: (state, action) => { state.register = getCommonStart() },
        registerSuccess: (state, action) => { state.register = getCommonSuccess(action.payload) },
        registerFailure: (state, action) => { state.register = getCommonFailure(action.payload) },

        //Forgot password
        forgotPasswordStart: (state, action) => { state.forgotPassword = getCommonStart() },
        forgotPasswordSuccess: (state, action) => { state.forgotPassword = getCommonSuccess(action.payload) },
        forgotPasswordFailure: (state, action) => { state.forgotPassword = getCommonFailure(action.payload) },

        //Reset Password
        resetPasswordStart: (state, action) => { state.resetPassword = getCommonStart() },
        resetPasswordSuccess: (state, action) => { state.resetPassword = getCommonSuccess(action.payload) },
        resetPasswordFailure: (state, action) => { state.resetPassword = getCommonFailure(action.payload) },

        //Profile
        profileStart: (state, action) => { state.profile = getCommonStart() },
        profileSuccess: (state, action) => { state.profile = getCommonSuccess(action.payload) },
        profileFailure: (state, action) => { state.profile = getCommonFailure(action.payload) },

        //Update Profile
        updateProfileStart: (state, action) => { state.updateProfile = getCommonStart() },
        updateProfileSuccess: (state, action) => { state.updateProfile = getCommonSuccess(action.payload) },
        updateProfileFailure: (state, action) => { state.updateProfile = getCommonFailure(action.payload) },

        //Dashboard
        dashboardStart: (state, action) => { state.dashboard = getCommonStart() },
        dashboardSuccess: (state, action) => { state.dashboard = getCommonSuccess(action.payload) },
        dashboardFailure: (state, action) => { state.dashboard = getCommonFailure(action.payload) },

        //Dashboard Charts
        dashboardChartStart: (state, action) => { state.dashboardChart = getCommonStart() },
        dashboardChartSuccess: (state, action) => { state.dashboardChart = getCommonSuccess(action.payload) },
        dashboardChartFailure: (state, action) => { state.dashboardChart = getCommonFailure(action.payload) },

        //Generate API key for user
        generateAPIKeyStart: (state, action) => { state.generateAPIKey = getCommonStart() },
        generateAPIKeySuccess: (state, action) => { state.generateAPIKey = getCommonSuccess(action.payload) },
        generateAPIKeyFailure: (state, action) => { state.generateAPIKey = getCommonFailure(action.payload) },

        //View API Key 
        viewAPIKeyStart: (state, action) => { state.viewAPIKey = getCommonStart() },
        viewAPIKeySuccess: (state, action) => { state.viewAPIKey = getCommonSuccess(action.payload) },
        viewAPIKeyFailure: (state, action) => { state.viewAPIKey = getCommonFailure(action.payload) },

        //Change Password
        changePasswordStart: (state, action) => { state.changePassword = getCommonStart() },
        changePasswordSuccess: (state, action) => { state.changePassword = getCommonSuccess(action.payload) },
        changePasswordFailure: (state, action) => { state.changePassword = getCommonFailure(action.payload) },

        // Create kyc applicant
        kycApplicantStart: (state, action) => { state.kycApplicant = getCommonStart() },
        kycApplicantSuccess: (state, action) => { state.kycApplicant = getCommonSuccess(action.payload) },
        kycApplicantFailure: (state, action) => { state.kycApplicant = getCommonFailure(action.payload) },

        // Update kyc status
        kycStatusUpdateStart: (state, action) => { state.kycStatusUpdate = getCommonStart() },
        kycStatusUpdateSuccess: (state, action) => { state.kycStatusUpdate = getCommonSuccess(action.payload) },
        kycStatusUpdateFailure: (state, action) => { state.kycStatusUpdate = getCommonFailure(action.payload) },

        // Verify Forgot Password
        verifyForgotPasswordStart: (state, action) => { state.verifyForgotPassword = getCommonStart() },
        verifyForgotPasswordSuccess: (state, action) => { state.verifyForgotPassword = getCommonSuccess(action.payload) },
        verifyForgotPasswordFailure: (state, action) => { state.verifyForgotPassword = getCommonFailure(action.payload) },

        //Delete Profile
        deleteProfileStart: (state, action) => { state.deleteProfile = getCommonStart() },
        deleteProfileSuccess: (state, action) => { state.deleteProfile = getCommonSuccess(action.payload) },
        deleteProfileFailure: (state, action) => { state.deleteProfile = getCommonFailure(action.payload) },

        // Email Verification
        emailVerifyStart: (state) => { state.emailVerify = getCommonStart() },
        emailVerifySuccess: (state, { payload }) => { state.emailVerify = getCommonSuccess(payload) },
        emailVerifyFailure: (state, { payload }) => { state.emailVerify = getCommonFailure(payload) },

        // Two Step Auth
        twoStepAuthStart: (state) => { state.twoStepAuth = getCommonStart() },
        twoStepAuthSuccess: (state, { payload }) => { state.twoStepAuth = getCommonSuccess(payload) },
        twoStepAuthFailure: (state, { payload }) => { state.twoStepAuth = getCommonFailure(payload) },

        // Update Two step status
        updateTwoStepStart: (state) => { state.updateTwoStep = getCommonStart() },
        updateTwoStepSuccess: (state, { payload }) => { state.updateTwoStep = getCommonSuccess(payload) },
        updateTwoStepFailure: (state, { payload }) => { state.updateTwoStep = getCommonFailure(payload) },

        // Resend OTP Start
        resendOtpStart: (state) => { state.resendOtp = getCommonStart() },
        resendOtpSuccess: (state, { payload }) => { state.resendOtp = getCommonSuccess(payload) },
        resendOtpFailure: (state, { payload }) => { state.resendOtp = getCommonFailure(payload) },

        //Update Email Verification Code
        updateEmailCodeStart: (state, action) => { state.updateEmailCode = getCommonStart() },
        updateEmailCodeSuccess: (state, action) => { state.updateEmailCode = getCommonSuccess(action.payload) },
        updateEmailCodeFailure: (state, action) => { state.updateEmailCode = getCommonFailure(action.payload) },

        updateEmailStart: (state, action) => { state.updateEmail = getCommonStart() },
        updateEmailSuccess: (state, action) => { state.updateEmail = getCommonSuccess(action.payload) },
        updateEmailFailure: (state, action) => { state.updateEmail = getCommonFailure(action.payload) },

        // Api Key
        apiKeyViewStart: (state, action) => { state.apiKeyView = getCommonStart() },
        apiKeyViewSuccess: (state, action) => { state.apiKeyView = getCommonSuccess(action.payload) },
        apiKeyViewFailure: (state, action) => { state.apiKeyView = getCommonFailure(action.payload) },

        apiKeyRegenerateStart: (state, action) => { state.apiKeyRegenerate = getCommonStart() },
        apiKeyRegenerateSuccess: (state, action) => { state.apiKeyRegenerate = getCommonSuccess(action.payload) },
        apiKeyRegenerateFailure: (state, action) => { state.apiKeyRegenerate = getCommonFailure(action.payload) },

        // Salt Key
        saltKeyViewStart: (state, action) => { state.saltKeyView = getCommonStart() },
        saltKeyViewSuccess: (state, action) => { state.saltKeyView = getCommonSuccess(action.payload) },
        saltKeyViewFailure: (state, action) => { state.saltKeyView = getCommonFailure(action.payload) },

    }
});


export const {
    setTheme,
    loginStart,
    loginSuccess,
    loginFailure,
    registerStart,
    registerSuccess,
    registerFailure,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordStart,
    resetPasswordSuccess,
    resetPasswordFailure,
    profileStart,
    profileSuccess,
    profileFailure,
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    dashboardStart,
    dashboardSuccess,
    dashboardFailure,
    dashboardChartFailure,
    dashboardChartStart,
    dashboardChartSuccess,
    generateAPIKeyStart,
    generateAPIKeySuccess,
    generateAPIKeyFailure,
    viewAPIKeyStart,
    viewAPIKeySuccess,
    viewAPIKeyFailure,
    kycApplicantStart,
    kycApplicantSuccess,
    kycApplicantFailure,
    kycStatusUpdateStart,
    kycStatusUpdateSuccess,
    kycStatusUpdateFailure,
    changePasswordStart,
    changePasswordSuccess,
    changePasswordFailure,
    verifyForgotPasswordFailure,
    verifyForgotPasswordStart,
    verifyForgotPasswordSuccess,
    deleteProfileFailure,
    deleteProfileStart,
    deleteProfileSuccess,
    emailVerifyFailure,
    emailVerifyStart,
    emailVerifySuccess,
    twoStepAuthFailure,
    twoStepAuthStart,
    twoStepAuthSuccess,
    updateTwoStepFailure,
    updateTwoStepStart,
    updateTwoStepSuccess,
    resendOtpFailure,
    resendOtpStart,
    resendOtpSuccess,
    updateEmailCodeFailure,
    updateEmailCodeStart,
    updateEmailCodeSuccess,
    updateEmailFailure,
    updateEmailStart,
    updateEmailSuccess,
    apiKeyRegenerateFailure,
    apiKeyRegenerateStart,
    apiKeyRegenerateSuccess,
    apiKeyViewFailure,
    apiKeyViewStart,
    apiKeyViewSuccess,
    saltKeyViewFailure,
    saltKeyViewStart,
    saltKeyViewSuccess,

} = AdminSlice.actions;

export default AdminSlice.reducer;