import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { apiKeyRegenerateStart, saltKeyViewStart, viewAPIKeyStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";
import CryptoJS from "crypto-js";

const ApiKeyModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("account_settings");
  const [skipRender, setSkipRender] = useState(true);
  const apiKeyView = useSelector((state) => state.admin.viewAPIKey);
  const saltKeyView = useSelector((state) => state.admin.saltKeyView);
  const [step, setStep] = useState(1);
  const [saltKey, setSaltKey] = useState("");

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid"))
      .required(t("password.required")),
  });

  const handleSubmit = (values) => {
    dispatch(
      props.apiKeyModal == 1
        ? viewAPIKeyStart(values)
        : saltKeyViewStart(values)
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      (!apiKeyView.loading || !saltKeyView.loading) &&
      (Object.keys(apiKeyView.data).length > 0 || Object.keys(saltKeyView.data).length > 0)
    ) {

      if(props.apiKeyModal == 2 && Object.keys(saltKeyView.data).length > 0) {
        console.log(saltKeyView, saltKeyView.data.salt_key)
        let key = process.env.REACT_APP_DECRYPTION_KEY;
        let encrypted_json = JSON.parse(atob(saltKeyView.data.salt_key));
        let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
          iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
        });
        const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        setSaltKey(salt_key_decrypted);
      }
      setStep(2);
    }
    setSkipRender(false);
  }, [apiKeyView, saltKeyView]);

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.apiKeyModal}
        onHide={props.closeApiKeyModal}
      >
        <Modal.Body>
          <Button onClick={props.closeApiKeyModal} className="modal-close">
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
            <div className="settlement-top-sec">
              <h3>{props.apiKeyModal == 1 || props.apiKeyModal == 2 ? t("view") : t("generate")} {props.apiKeyModal == 1 ? t("api_key") : t("salt_key")}</h3>
            </div>
            <Row className="align-items-center">
              <Col md={12}>
                {step == 1 && (
                  <div className="bank-account-form-sec">
                    <Formik
                      initialValues={{
                        password: "",
                        api_key_type: props.apiKeyModal == 1 ? 1 : 2,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, setFieldValue }) => (
                        <FORM className="bank-account-form">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>{t("password.label")}</Form.Label>
                            <Field
                              name="password"
                              className="form-control"
                              type="password"
                              placeholder={t("password.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="password"
                              className="errorMsg"
                            />
                          </Form.Group>
                          <div className="request-payout-payment-method-btn-sec">
                            <Button
                              className="efi-cancel-btn"
                              onClick={props.closeApiKeyModal}
                            >
                              {t("cancel")}
                            </Button>
                            <Button
                              className="efi-auth-btn"
                              disabled={apiKeyView.buttonDisable || saltKeyView.buttonDisable}
                              type="submit"
                            >
                              {(saltKeyView.buttonDisable || apiKeyView.buttonDisable) ? (
                                <ButtonLoader />
                              ) :
                                props.apiKeyModal == 1 || props.apiKeyModal == 2 ? t("view_btn.text") : t("view_btn.generate")
                              }
                            </Button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                )}
                {step == 2 && <div className="payment-link-created-box">
                  <div className="payment-link-created-header-sec">
                    <div className="payment-link-created-header-icon-sec">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width={24} height={24}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                      </svg>
                    </div>
                    <h3>{props.apiKeyModal == 1 ? t("api_key") : t("salt_key")}</h3>
                  </div>
                  <div className="payment-link-created-body-sec">
                    <div className="payment-link-created-card">
                      <div className="payment-link-created-left-sec">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width={16} height={16}>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                        </svg>
                        <span>
                          {props.apiKeyModal == 1 ? apiKeyView.data.api_key : saltKey}
                        </span>
                      </div>
                      <div className="payment-link-created-right-sec">
                        <CopyToClipboard
                          text={props.apiKeyModal == 1 ? apiKeyView.data.api_key : saltKey}
                          onCopy={() => getSuccessNotificationMessage(props.apiKeyModal == 1 ? t("api_key_copied_to_clipboard") : t("salt_key_copied_to_clipboard"))}>
                          <Button className="copy-btn">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              fill="#5C9EEB"
                              height="20"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                            </svg> */}
                             <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0092 7.28671C11.9771 7.07162 13.139 7 14.5 7C15.861 7 17.0229 7.07162 17.9908 7.28671C18.9699 7.50433 19.8106 7.88186 20.4644 8.53561C21.1181 9.18936 21.4957 10.0301 21.7133 11.0092C21.9284 11.9771 22 13.139 22 14.5C22 15.861 21.9284 17.0229 21.7133 17.9908C21.4957 18.9699 21.1181 19.8106 20.4644 20.4644C19.8106 21.1181 18.9699 21.4957 17.9908 21.7133C17.0229 21.9284 15.861 22 14.5 22C13.139 22 11.9771 21.9284 11.0092 21.7133C10.0301 21.4957 9.18936 21.1181 8.53561 20.4644C7.88186 19.8106 7.50433 18.9699 7.28671 17.9908C7.07162 17.0229 7 15.861 7 14.5C7 13.139 7.07162 11.9771 7.28671 11.0092C7.50433 10.0301 7.88186 9.18936 8.53561 8.53561C9.18936 7.88186 10.0301 7.50433 11.0092 7.28671Z" fill="#878787" />
                                  <path d="M6.08776 16.7303C6.02331 16.0357 6 15.2899 6 14.5C6 13.116 6.07156 11.8676 6.31053 10.7923C6.55451 9.69447 6.99883 8.65819 7.82851 7.82851C8.65819 6.99883 9.69447 6.55451 10.7923 6.31053C11.8676 6.07156 13.116 6 14.5 6C15.2899 6 16.0357 6.02331 16.7303 6.08776C16.4704 4.86356 15.9749 3.87161 15.0472 3.17348C13.7659 2.20926 11.886 2 9.5 2C8.13899 2 6.97706 2.07162 6.00924 2.28671C5.03006 2.50433 4.18936 2.88186 3.53561 3.53561C2.88186 4.18936 2.50433 5.03006 2.28671 6.00924C2.07162 6.97706 2 8.13899 2 9.5C2 11.886 2.20926 13.7659 3.17348 15.0472C3.87161 15.9749 4.86356 16.4704 6.08776 16.7303Z" fill="#878787" />
                                </svg>
                            <span>{t("copy")}</span>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="payment-link-created-btn-sec">
                      <Button onClick={props.closeApiKeyModal} className="efi-payment-btn">Done</Button>
                    </div>
                  </div>
                </div>}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApiKeyModal;
