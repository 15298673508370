import React, { useEffect, useState } from "react";
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import { apiUrl } from "./Environment";
import configuration from "react-global-configuration";
import PageLoader from "./components/Helper/PageLoader";

//Css
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {

  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(
        `${apiUrl}get_settings_json`
      );
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      <ToastContainer />
      {configLoading ? <PageLoader /> : <Base />}
    </Provider>
  );
}

export default App;
