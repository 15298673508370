import React, { useEffect, useState } from "react";
import {
  Image,
  Button,
} from "react-bootstrap";
import LineGraph from "./LineGraph";
import BarGraph from "./BarGraph";
import RecentTransaction from "./RecentTransaction";
import { useDispatch, useSelector } from "react-redux";
import { dashboardChartStart, dashboardStart, profileStart } from "../../store/slices/AdminSlice";
import { transactionListStart } from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import DashboardLoader from "../Helper/DashboardLoader";
import VerifyKYCModal from "../Account/VerifyKYCModal";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import PieGraph from "./PieGraph";
import NoDataFound from "../Helper/NoDataFound";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { useTranslation, withTranslation } from "react-multi-lang";

const DashBoardIndex = (props) => {
  const t = useTranslation("dashboard");
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.admin.dashboard);
  const dashboardChart = useSelector((state) => state.admin.dashboardChart);
  const profile = useSelector((state) => state.admin.profile);

  const [verifyKYC, setVerifyKYC] = useState(false);

  const closeVerifyKYCModal = () => {
    setVerifyKYC(false);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      profile.data.kyc_status == 3 && dispatch(dashboardStart());
      dispatch(dashboardChartStart());
      dispatch(transactionListStart());
    }
  }, []);

  return profile.loading ? (
    <DashboardLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      {profile.data.kyc_status == 3 ? (
        <React.Fragment>
          <div className="dashboard-sec">
            <div className="dashboard-transaction-multiple-graph-sec">
              <div className="dashboard-box">
                {dashboard.loading ? (
                  [...Array(6)].map((i, key) => (
                    <Skeleton key={key} height={150} />
                  ))
                ) : Object.keys(dashboard.data).length > 0 ? (
                  <React.Fragment>
                    <div className="dashboard-card">
                      <div className="dashboard-card-icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          version="1.1"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          fill="#3D9F99"
                        >
                          <path
                            d="M70.697 395.352h80.633c5.658 0 10.246-4.252 10.246-9.498v-143.72c0-5.246-4.588-9.499-10.246-9.499H70.697c-5.658 0-10.246 4.253-10.246 9.499v143.72c0 5.246 4.588 9.498 10.246 9.498zM215.684 395.352h80.631c5.658 0 10.248-4.252 10.248-9.498V141.117c0-5.246-4.59-9.498-10.248-9.498h-80.631c-5.66 0-10.248 4.252-10.248 9.498v244.736c0 5.247 4.588 9.499 10.248 9.499zM360.668 395.352h80.631c5.66 0 10.248-4.252 10.248-9.498V25.964c0-5.246-4.588-9.499-10.248-9.499h-80.631c-5.66 0-10.248 4.253-10.248 9.499v359.89c0 5.246 4.588 9.498 10.248 9.498z"
                            className="st0"
                          ></path>
                          <path
                            d="M495.426 438.633L16.574 438.633 0 438.633 0 495.535 16.574 495.535 495.426 495.535 512 495.535 512 438.633z"
                            className="st0"
                          ></path>
                        </svg>
                      </div>
                      <div className="dashboard-card-info">
                        <p>{t("dashboard_card.total_transactions")}</p>
                        <h4>{dashboard.data.total_transactions}</h4>
                      </div>
                    </div>
                    <div className="dashboard-card">
                      <div className="dashboard-card-icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="#3D9F99"
                          viewBox="0 0 512 512"
                        >
                          <path d="M387.118 402.176c-2.824 2.824-6.647 4.412-10.647 4.412s-7.823-1.588-10.647-4.412l-90.353-90.353c-5.882-5.882-5.882-15.412 0-21.294s15.412-5.882 21.294 0l79.706 79.706L512 234.706v-8.824H6.024A6.024 6.024 0 000 231.906V391.53c0 24.95 20.226 45.176 45.176 45.176h421.647c24.95 0 45.176-20.226 45.176-45.176V284.565c0-2.683-3.244-4.027-5.141-2.13zm-221.471-55.823h-60.235c-8.324 0-15.059-6.735-15.059-15.059s6.735-15.059 15.059-15.059h60.235c8.324 0 15.059 6.735 15.059 15.059s-6.735 15.059-15.059 15.059zM512 189.741V120.47c0-24.95-20.226-45.176-45.176-45.176H45.176C20.226 75.294 0 95.52 0 120.471v69.271a6.024 6.024 0 006.024 6.024h499.953a6.025 6.025 0 006.023-6.025z"></path>
                        </svg>
                      </div>
                      <div className="dashboard-card-info">

                        <p>{t("dashboard_card.payin_amount_label")}</p>
                        <h4>{dashboard.data.total_success_transactions}</h4>
                      </div>
                    </div>
                    <div className="dashboard-card">
                      <div className="dashboard-card-icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          version="1.1"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                          fill="#3D9F99"
                        >
                          <path
                            d="M299.914 366.962l-47.609-21.422 16.656-59.5-36.875-39.281-7.453-51.313c-27.453 1.891-55.609 9.734-82.203 24.547-9.438 5.266-17.875 10.703-25.594 16.172-20.797-25.031-73.156-29.234-66.406-9.75 6.109 17.641 15.094 36.984 19.281 48.453-15.734 13.266-30.297 23.547-49.406 27.094-17.469 3.25-22.656 7.906-19.375 24.953l7.219 57.109s20.359 11.766 27.578 15.75c15.922 8.812 47.25 28 93.547 42.265l17.563 55.063h43.922v-41.5c17.547 2.438 36.406 3.875 56.656 3.875 10.5 0 20.375-.719 29.656-2.047v39.672h43.922l20.406-63.984c-3.391 5.25-5.563 6.063 0 0l-41.484-28.078v-38.078zM283.976 157.603a6.856 6.856 0 009.594-1.25l94.234-123.016c2.297-2.984 1.734-7.281-1.266-9.578l-9.734-7.453a6.75 6.75 0 00-6.563-.953l-56.5 21.313a6.825 6.825 0 00-3.031 2.219l-63.047 82.313c-2.281 2.984-1.719 7.281 1.266 9.563l35.047 26.842zM509.32 205.447l-137.36-105.25c-2.984-2.297-7.281-1.703-9.563 1.266l-18.453 24.094c-2.297 3-1.719 7.281 1.266 9.578l137.344 105.234c3 2.281 7.281 1.719 9.578-1.266l18.453-24.094c2.298-3 1.735-7.281-1.265-9.562z"
                            className="st0"
                          ></path>
                          <path
                            d="M424.195 251.541c-3.313-.344-15.484-.328-24.719.016-30.297-41.078-84.938-63.672-142.703-59.688l7.438 51.328 36.891 39.266-13.094 51.172 42.844 17.875 1.188 49.969 44.047 22.625c33.313-23.812 55.469-97.484 40.828-139.609-.672-1.938-1.516-3.766-2.281-5.641 4.359-2.453 9-5.594 12.579-9.359 9.42-9.892 2.154-17.454-3.018-17.954z"
                            className="st0"
                          ></path>
                        </svg>
                      </div>
                      <div className="dashboard-card-info">
                        <p>{t("dashboard_card.payin_merchant_commission_label")}</p>
                        <h4>{dashboard.data.total_failed_transactions}</h4>
                      </div>
                    </div>
                    {/* <div className="dashboard-card">
                      <p>{t("dashboard_card.inr_payout_amount_label")}</p>
                      <h4>{dashboard.data.inr_pay_out_amount}</h4>
                    </div> */}
                    <div className="dashboard-card">
                      <div className="dashboard-card-icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="#3D9F99"
                          viewBox="0 0 512 512"
                        >
                          <path d="M436 512H76c16.569 0 30-13.431 30-30 0-11.046 8.954-20 20-20h320c11.046 0 20 8.954 20 20 0 16.569-13.431 30-30 30z"></path>
                          <path d="M405.302 14.815C400.788 17.778 395.371 20 387.245 20c-24.378 0-24.378-20-48.755-20-24.374 0-24.374 20-48.748 20-24.375 0-24.375-20-48.75-20-24.374 0-24.374 20-48.748 20-24.373 0-24.373-20-48.747-20-24.375 0-24.375 20-48.75 20-8.125 0-13.541-2.222-18.055-5.185C63.52 6.167 46 15.984 46 31.741v434.277c0 9.096 7.6 16.418 16.796 15.962 7.015-.348 13-5.419 14.722-12.228C82.994 448.083 102.654 432 126 432h290c11.046 0 20-8.954 20-20V31.745c0-15.76-17.522-25.578-30.698-16.93zM271 321c0 11.046-8.954 20-20 20H131c-11.046 0-20-8.954-20-20s8.954-20 20-20h120c11.046 0 20 8.954 20 20zm80-70H131c-11.046 0-20-8.954-20-20s8.954-20 20-20h220c11.046 0 20 8.954 20 20s-8.954 20-20 20zm0-90H131c-11.046 0-20-8.954-20-20s8.954-20 20-20h220c11.046 0 20 8.954 20 20s-8.954 20-20 20z"></path>
                        </svg>
                      </div>
                      <div className="dashboard-card-info">
                        <p>{t("dashboard_card.total_transactions_label")}</p>
                        <h4>{dashboard.data.total_changeback_transactions}</h4>
                      </div>
                    </div>
                    <div className="dashboard-card">
                      <div className="dashboard-card-icons">
                        <svg 
                          width="32"
                          height="32"
                         fill="#3D9F99"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path d="M486.214 273.825c-19.468-24.702-47.387-40.343-78.616-44.046a114.465 114.465 0 00-4.541-.445 116.587 116.587 0 00-18.62-.031c-23.026 1.769-45.018 10.313-63.565 24.94-47.387 37.332-58.321 104.032-27.371 154.202a116.131 116.131 0 007.789 11.141 117.3 117.3 0 006.413 7.479c18.94 20.378 44.242 33.247 72.203 36.557 4.707.559 9.403.838 14.068.838 26.305 0 51.68-8.751 72.658-25.282 24.702-19.468 40.343-47.387 44.035-78.616 3.694-31.23-4.985-62.035-24.453-86.737zm-12.671 96.833l-43.249 43.167c-1.81 1.81-4.2 2.72-6.579 2.72s-4.769-.91-6.589-2.741a9.3 9.3 0 01.021-13.158l27.319-27.267H320.53a9.311 9.311 0 010-18.62h146.443a9.293 9.293 0 018.596 5.751 9.306 9.306 0 01-2.026 10.148zm-6.569-31.995H320.53a9.31 9.31 0 01-8.596-5.751 9.327 9.327 0 012.017-10.148l43.218-43.187c3.641-3.641 9.537-3.641 13.168 0 3.641 3.641 3.631 9.537 0 13.168l-27.319 27.298h123.955a9.311 9.311 0 01.001 18.62zM322.703 104.521H1.059C3.2 94.467 11.237 86.729 21.747 85.291l273.119-37.477c7.551-1.045 14.254.91 19.913 5.782 5.72 4.955 8.617 11.306 8.617 18.858v32.078c-.228-.011-.466-.011-.693-.011zm-50.542 303.924H62.203C28.181 408.445.5 380.753.5 346.711v-223.57h322.203c34.043 0 61.734 27.691 61.734 61.734v25.767c-.476.021-.952.052-1.427.093-26.843 2.069-52.321 12.051-73.661 28.881-51.474 40.55-66.452 111.419-37.188 168.829z"></path>
                        </svg>
                      </div>
                      <div className="dashboard-card-info">
                        <p>{t("dashboard_card.wallet_total_label")}</p>
                        <h4>{dashboard.data.total_refunded_transactions}</h4>
                      </div>
                    </div>
                    {/* <div className="dashboard-card">
                      <p>{t("dashboard_card.wallet_used_label")}</p>
                      <h4>{dashboard.data.user_wallet.used}</h4>
                    </div> */}
                    <div className="dashboard-card">
                      <div className="dashboard-card-icons">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <g fill="#3D9F99">
                                <path d="M19.972 8.75h-2.94c-1.27 0-2.03-.76-2.03-2.03V3.78c0-1.27.76-2.03 2.03-2.03h2.94c1.27 0 2.03.76 2.03 2.03v2.94c0 1.27-.76 2.03-2.03 2.03zm.22-3.31c-.12-.12-.28-.18-.44-.18s-.32.06-.44.18l-.18.18V3.38c0-.35-.28-.63-.63-.63s-.63.28-.63.63v2.24l-.18-.18c-.24-.24-.64-.24-.88 0s-.24.64 0 .88l1.25 1.25c.05.05.12.09.19.12.02.01.04.01.06.02.05.02.1.03.16.03h.06c.07 0 .13-.01.2-.04h.02c.07-.03.13-.07.18-.12.01-.01.01-.01.02-.01l1.25-1.25c.24-.24.24-.64-.01-.88zM2.002 11.46v5c0 2.29 1.85 4.14 4.14 4.14h11.71c2.29 0 4.15-1.86 4.15-4.15v-4.99c0-.67-.54-1.21-1.21-1.21H3.212c-.67 0-1.21.54-1.21 1.21zm6 5.79h-2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2c.41 0 .75.34.75.75s-.34.75-.75.75zm6.5 0h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75zM13.502 4.61v2.93c0 .67-.54 1.21-1.21 1.21h-9.08c-.68 0-1.21-.56-1.21-1.23.01-1.13.46-2.16 1.21-2.91s1.79-1.21 2.93-1.21h6.15c.67 0 1.21.54 1.21 1.21z"></path>
                              </g>
                          </svg>
                      </div>
                      <div className="dashboard-card-info">
                        <p>{t("dashboard_card.wallet_remaining_label")}</p>
                        <h4>{dashboard.data.total_amount}</h4>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
              {dashboardChart.loading ?
                <div className="dashboard-transaction-box">
                  <div className="dashboard-transaction-card">
                    <Skeleton height={400} />
                  </div>
                </div>
                : Object.keys(dashboardChart.data).length > 0 ? (
                  <div className="dashboard-transaction-box">
                    <div className="dashboard-transaction-card">
                      <div className="dashboard-transaction-top-sec">
                        <div className="dashboard-transaction-left-sec">
                          <h5>{t("dashboard_card.transaction_overview_label")}</h5>
                        </div>
                      </div>
                      <div className="dasboard-transaction-graph-sec">
                        <LineGraph payinData={dashboardChart.data.last_x_days_transactions} count={dashboardChart.data.total_transactions_count} />
                      </div>
                    </div>
                  </div>
                ) : null}
            </div>

            <div className="dashboard-body-sec">
              {dashboardChart.loading ? <React.Fragment>
                <div className="dashboard-transaction-box">
                  <div className="dashboard-transaction-card">
                    <Skeleton height={300} />
                  </div>
                </div>
                <div className="dashboard-transaction-multiple-graph-sec">
                  <div className="dashboard-transaction-multiple-card">
                    <Skeleton height={300} />
                  </div>
                  <div className="dashboard-transaction-multiple-card">
                    <Skeleton height={300} />
                  </div>
                </div>
              </React.Fragment> : Object.keys(dashboardChart.data).length > 0 ? <React.Fragment>
                {/* <div className="dashboard-transaction-box">
                  <div className="dashboard-transaction-card">
                    <div className="dashboard-transaction-top-sec">
                      <div className="dashboard-transaction-left-sec">
                        <h5>{t("dashboard_card.transaction_overview_label")}</h5>
                      </div>
                    </div>
                    <div className="dasboard-transaction-graph-sec">
                      <LineGraph payinData={dashboardChart.data.last_x_months_pay_in} payoutData={dashboardChart.data.last_x_months_pay_out} count={dashboardChart.data.total_transactions_count} />
                    </div>
                  </div>
                </div> */}
                <div className="dashboard-transaction-multiple-graph-sec">
                  <div className="dashboard-transaction-multiple-card">
                    <div className="dashboard-transaction-top-multiple-sec">
                      <div className="dashboard-transaction-multiple-left-sec">
                        <h5>{t("dashboard_card.last_10_days_transactions_label")}</h5>
                      </div>
                    </div>
                    <div className="dashboard-transaction-analytics-graph-sec">
                      <BarGraph data={dashboardChart.data.last_x_days_transactions} count={dashboardChart.data.total_transactions_count} />
                    </div>
                  </div>
                  <div className="dashboard-transaction-multiple-card no-back-border">
                    <div className="dashboard-transaction-top-multiple-sec">
                      <div className="dashboard-transaction-multiple-left-sec">
                        <h5>{t("dashboard_card.transaction_traffic_label")}</h5>
                      </div>
                    </div>
                    <div className="dashboard-transaction-analytics-graph-sec">
                      <PieGraph data={dashboardChart.data.total_transactions} count={dashboardChart.data.total_transactions_count} />
                    </div>
                  </div>
                </div>
              </React.Fragment> : null}
              <RecentTransaction />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="profile-account-sec">
          <div className="profile-account-box">
            <div className="profile-account-header-sec">
              <div className="profile-account-header-left-sec">
                <div className="profile-account-user-details-sec">
                  <div className="profile-account-user-img-sec">
                    <CustomLazyLoad
                      className="profile-account-user-img"
                      src={
                        profile.data.picture ||
                        window.location.origin + "/img/user-img.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="profile-account-user-info">
                    <h4>{profile.data.name}</h4>
                    <ul className="list-unstyled profile-account-user-info-list">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 14"
                        >
                          <path
                            fill="#020E30"
                            d="M12.667.5H3.333c-.883 0-1.73.313-2.356.866C.353 1.92.001 2.671 0 3.455v7.09c.001.784.353 1.535.977 2.089.625.553 1.473.865 2.356.866h9.334c.883 0 1.73-.313 2.356-.866.624-.554.976-1.305.977-2.088V3.455c-.001-.784-.353-1.535-.977-2.089C14.398.813 13.55.501 12.667.5zM3.333 1.682h9.334c.399 0 .789.107 1.119.306.33.199.586.48.734.809L9.415 7.323A2.143 2.143 0 018 7.84c-.53 0-1.039-.187-1.415-.518L1.48 2.797c.148-.329.404-.61.734-.81.33-.198.72-.304 1.12-.305zm9.334 10.636H3.333c-.53 0-1.039-.187-1.414-.519a1.677 1.677 0 01-.586-1.253V4.34l4.31 3.817c.625.553 1.473.864 2.357.864.884 0 1.732-.31 2.357-.864l4.31-3.817v6.205c0 .47-.211.92-.586 1.253-.375.332-.884.52-1.414.52z"
                          ></path>
                        </svg>
                        <span>{profile.data.email}</span>
                      </li>
                      <li>
                        <svg
                          xmlns="http://w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#0C0E10"
                            fillRule="evenodd"
                            d="M1.607 1.056C3.143-.48 5.736-.363 6.806 1.554l.593 1.063c.698 1.251.4 2.83-.622 3.866a.576.576 0 00-.095.308c-.012.234.072.776.912 1.615.84.84 1.38.924 1.616.912a.576.576.0 00.308-.095c1.034-1.022 2.614-1.32 3.865-.622l1.063.595c1.917 1.07 2.034 3.66.498 5.197-.822.823-1.914 1.553-3.199 1.6-1.902.074-5.062-.418-8.19-3.548C.425 9.317-.066 6.158.005 4.255c.049-1.285.78-2.377 1.6-3.199zm4 1.166c-.548-.982-2.02-1.207-3.03-.195-.709.708-1.17 1.49-1.2 2.28-.06 1.587.332 4.354 3.147 7.168 2.817 2.817 5.583 3.209 7.17 3.149.788-.03 1.572-.492 2.28-1.2 1.01-1.011.786-2.484-.196-3.031l-1.064-.594c-.66-.368-1.59-.243-2.242.41-.064.064-.472.444-1.196.479-.74.037-1.637-.296-2.651-1.311-1.016-1.015-1.349-1.912-1.312-2.654.034-.723.416-1.131.479-1.195.653-.652.778-1.581.41-2.242l-.595-1.064z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>
                          +{profile.data.country_code} {profile.data.mobile}
                        </span>
                      </li>
                    </ul>
                    <p>{t("kyc_not_verified_label")}</p>
                  </div>
                </div>
              </div>
              {profile.data.kyc_status === 3 ? (
                <div className="profile-account-header-right-sec">
                  <div className="profile-account-header-kyc-verified-btn">
                    <Image
                      className="kyc-verified-icon"
                      src={window.location.origin + "/img/kyc-verified.svg"}
                      type="image/png"
                    />
                  </div>
                  <h5>{t("kyc_verified_label")}</h5>
                </div>
              ) : (
                <div className="profile-account-header-kyc-verified-btn">
                  <Button
                    className="efi-auth-btn"
                    onClick={() => setVerifyKYC(true)}
                  >
                    {t("verify_kyc_btn")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {verifyKYC && (
        <VerifyKYCModal
          verifyKYC={verifyKYC}
          closeVerifyKYCModal={closeVerifyKYCModal}
          setVerifyKYC={setVerifyKYC}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText={t("retry_btn")}
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default withTranslation(DashBoardIndex);
