import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NoDataFound from "../Helper/NoDataFound";

const BarGraph = (props) => {
  const data = props.data.model_data.map((value, index) => ({
    name: props.data.days[index].slice(0,6),
    transactions: value,
  }));

  return (
    <div className="bar-graph-sec">
      <ResponsiveContainer width="100%" height={400}>
        {props.count ? <BarChart width={600} height={300} data={data}>
          <XAxis style={{ fontSize:"0.8em" }} dataKey="name" stroke="#000" interval={0} angle={0} />
          <YAxis width={12} />
          <Tooltip
            wrapperStyle={{ width: 120, backgroundColor: "#ccc" }}
            formatter={(value) => [`Transactions: ${value}`,]}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Bar dataKey="transactions" fill="#3D9F99" barSize={30} />
        </BarChart> : <NoDataFound/>}
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
