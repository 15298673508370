import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import MainLayout from "../layouts/MainLayout";
import DashBoardIndex from "../DashBoard/DashBoardIndex";
import {
  AdminLayout,
  AuthLayout,
  EmptyLayout,
} from "../layouts";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import {
  setTranslations,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import jp from "../translations/jp.json";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import ResetPasswordIndex from "../Auth/ResetPasswordIndex";
import AccountIndex from "../Account/AccountIndex";
import Page404 from "../Helper/Page404";
import InvoiceTransactionsIndex from "../Transactions/TransactionsIndex";
import ProfileSettings from "../Account/ProfileSettings";

const $ = window.$;
setTranslations({ en, es, jp });

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) =>
  localStorage.getItem("token") || sessionStorage.getItem("token") ? (
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/login" }} />
  );

const Base = () => {
  const [loading, setLoading] = useState(true);
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  return (
    <>
      <Helmet>
				<title>{configuration.get("configData.site_name")}</title>
				<link
					rel="icon"
					type="image/png"
					href={configuration.get("configData.site_icon")}
				/>
				<script src={configuration.get("configData.head_scripts")}></script>
			</Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/login"}
            element={
              <AppRoute
                component={LoginIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute
                component={RegisterIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute
                component={ForgotPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/reset-password/:token"}
            element={
              <AppRoute
                component={ResetPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route path={"/"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={DashBoardIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>

          <Route path={"/transactions"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={InvoiceTransactionsIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>

          <Route
              path={"/account-settings"}
              element={
                <PrivateRoute
                  component={AccountIndex}
                  layout={AdminLayout}
                />
              }
            />

          <Route
              path={"/profile-settings"}
              element={
                <PrivateRoute
                  component={ProfileSettings}
                  layout={AdminLayout}
                />
              }
            />

          <Route
            path={"*"}
            element={
              <AppRoute
                component={Page404}
                layout={MainLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Base;
