import React, { useEffect, useState } from "react";
import { Button, Image, Form, InputGroup, Row, Col } from "react-bootstrap";
import ChangeMobileNumberModal from "./ChangeMobileNumberModal";
import ChangeUserNameModal from "./ChangeUserNameModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeEmailAddressModal from "./ChangeEmailAddressModal";
import DeleteAccountModal from "./DeleteAccountModal";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import {
  profileStart,
  updateProfileStart,
} from "../../store/slices/AdminSlice";
import useLogout from "../../hooks/useLogout";
import VerifyKYCModal from "./VerifyKYCModal";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import OTPVerificationModal from "../Auth/OTPVerificationModal";
import ApiKeyModal from "./ApiKeyModal";
import { useTranslation } from "react-multi-lang";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";

const AccountIndex = (props) => {
  const dispatch = useDispatch();
  const { logout } = useLogout();
  const t = useTranslation("account_settings");
  const profile = useSelector((state) => state.admin.profile);
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const kycStatusUpdate = useSelector((state) => state.admin.kycStatusUpdate);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const [changeUserName, setChangeUserName] = useState(false);
  const [changeMobileNumber, setchangeMobileNumber] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [newEmail, setNewEmail] = useState();

  const closeChangeUserNameModal = () => {
    setChangeUserName(false);
  };
  const closeChangeMobileNumberModal = () => {
    setchangeMobileNumber(false);
  };

  const [changePassword, setChangePassword] = useState(false);

  const closeChangePasswordModal = () => {
    setChangePassword(false);
  };

  const [changeEmailAddress, setChangeEmailAddress] = useState(false);

  const closeChangeEmailAddressModal = () => {
    setChangeEmailAddress(false);
  };

  const [deleteAccount, setDeleteAccount] = useState(false);

  const closeDeleteAccountModal = () => {
    setDeleteAccount(false);
  };

  const [verifyKYC, setVerifyKYC] = useState(false);

  const closeVerifyKYCModal = () => {
    setVerifyKYC(false);
  };

  const [apiKeyModal, setApiKeyModal] = useState(false);

  const closeApiKeyModal = () => {
    setApiKeyModal(false);
  };

  const handleUpdateProfilePicture = (image) => {
    image &&
      dispatch(
        updateProfileStart({
          picture: image,
          name: profile.data.name,
          email: profile.data.email,
          mobile: profile.data.mobile,
          country_code: profile.data.country_code,
          citizenship: profile.data.citizenship,
          residence: profile.data.residence,
        })
      );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeVerifyKYCModal();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmailCode.loading &&
      Object.keys(updateEmailCode.data).length > 0
    ) {
      setShowVerify(newEmail);
    }
    setSkipRender(false);
  }, [updateEmailCode]);

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  return profile.loading ? (
    <PageLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
      <div className="profile-account-sec">
        <div className="settlement-top-sec">
          <h3>{t("heading")}</h3>
        </div>
        <div className="profile-account-box">
          {/* <div className="profile-account-header-sec">
            <div className="profile-account-header-left-sec">
              <div className="profile-account-user-details-sec">
                <div className="profile-account-user-img-sec">
                  <CustomLazyLoad
                    className="profile-account-user-img"
                    src={
                      profile.data.picture ||
                      window.location.origin + "/img/user-img.svg"
                    }
                    type="image/png"
                  />
                </div>
                <div className="profile-account-user-info">
                  <label className="profile-account-user-img-edit-icon">
                    <input
                      type="file"
                      accept="image/png, image/jpeg,, image/jpg"
                      size="60"
                      disabled={updateProfile.buttonDisable}
                      onChange={(e) =>
                        handleUpdateProfilePicture(e.target.files[0])
                      }
                    />
                    Change
                  </label>
                </div>
              </div>
            </div>
            <div className="profile-left-wrap-area">
              <div className="profile-left-action-area">
                {profile.data.kyc_status == 3 ? (
                  <div className="profile-account-header-right-sec">
                    <div className="profile-account-header-kyc-verified-btn">
                      <Image
                        className="kyc-verified-icon"
                        src={window.location.origin + "/img/kyc-verified.svg"}
                        type="image/png"
                      />
                    </div>
                    <h5>{t("kyc_verified")}</h5>
                  </div>
                ) : (
                  <div className="profile-account-header-kyc-verified-btn">
                    <Button
                      className="efi-auth-btn"
                      onClick={() => setVerifyKYC(true)}
                    >
                      {t("verify_kyc")}
                    </Button>
                  </div>
                )}
                <Button className="efi-secondary-btn" onClick={logout}>
                  {t("logout")}
                </Button>
                <Button
                  className="efi-danger-btn"
                  onClick={() => setDeleteAccount(true)}
                >
                  {t("delete")}
                </Button>
              </div>
            </div>
          </div> */}
          <div className="profile-account-body-sec">
            <div className="profile-account-list-box">
              {/* <div className="profile-details-info-card-wrap">
                <div className="settlement-top-sec">
                  <h3>Profile Info</h3>
                </div>
                <div className="profile-new-frame">
                  <div className="profile-input-wrap profile-form-frame">
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>{t("username")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={profile.data.name}
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setChangeUserName(true)}
                            >
                              {t("edit")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>{t("change_email_address")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={profile.data.email}
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setChangeEmailAddress(true)}
                            >
                              {t("edit")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Change Number</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder={"+"+profile.data.country_code + " " + profile.data.mobile}
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setchangeMobileNumber(true)}
                            >
                              {t("edit")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>{t("change_password")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="************"
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setChangePassword(true)}
                            >
                              {t("edit")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                  </div>
                </div>
              </div> */}
              <div className="profile-details-info-card-wrap">
                <div className="profile-new-frame">
                  <div className="profile-input-wrap profile-form-frame">
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>{t("api_key")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="************"
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setApiKeyModal(1)}
                            >
                              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12ZM6.24234 6.34315C9.36653 3.21895 14.4319 3.21895 17.556 6.34315L19.7239 8.511C20.3508 9.13781 20.8909 9.67788 21.2653 10.1685C21.6665 10.6944 21.9703 11.2792 21.9703 12C21.9703 12.7208 21.6665 13.3056 21.2653 13.8315C20.8909 14.3221 20.3508 14.8622 19.7239 15.489L17.556 17.6569C14.4319 20.781 9.36653 20.781 6.24234 17.6569L4.07447 15.489C3.44759 14.8622 2.90746 14.3221 2.5331 13.8315C2.1319 13.3056 1.82812 12.7208 1.82812 12C1.82812 11.2792 2.1319 10.6944 2.5331 10.1685C2.90746 9.67788 3.44759 9.13781 4.07447 8.51101C4.08994 8.49555 4.10545 8.48003 4.12102 8.46447L6.24234 6.34315Z" fill="#252525B2" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#252525B2" />
                              </svg>
                              {t("view")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>{t("salt_key")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="************"
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setApiKeyModal(2)}
                            >
                              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12ZM6.24234 6.34315C9.36653 3.21895 14.4319 3.21895 17.556 6.34315L19.7239 8.511C20.3508 9.13781 20.8909 9.67788 21.2653 10.1685C21.6665 10.6944 21.9703 11.2792 21.9703 12C21.9703 12.7208 21.6665 13.3056 21.2653 13.8315C20.8909 14.3221 20.3508 14.8622 19.7239 15.489L17.556 17.6569C14.4319 20.781 9.36653 20.781 6.24234 17.6569L4.07447 15.489C3.44759 14.8622 2.90746 14.3221 2.5331 13.8315C2.1319 13.3056 1.82812 12.7208 1.82812 12C1.82812 11.2792 2.1319 10.6944 2.5331 10.1685C2.90746 9.67788 3.44759 9.13781 4.07447 8.51101C4.08994 8.49555 4.10545 8.48003 4.12102 8.46447L6.24234 6.34315Z" fill="#252525B2" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#252525B2" />
                              </svg>
                              {t("view")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>{t("merchant_id")}</Form.Label>
                        <InputGroup>
                          <div className="merchet-key-new-card">
                            <div className="payment-link-created-left-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                width={16}
                                height={16}
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                                />
                              </svg> */}
                              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8.29344C22 11.7692 19.1708 14.5869 15.6807 14.5869C15.0439 14.5869 13.5939 14.4405 12.8885 13.8551L12.0067 14.7333C11.4883 15.2496 11.6283 15.4016 11.8589 15.652C11.9551 15.7565 12.0672 15.8781 12.1537 16.0505C12.1537 16.0505 12.8885 17.075 12.1537 18.0995C11.7128 18.6849 10.4783 19.5045 9.06754 18.0995L8.77362 18.3922C8.77362 18.3922 9.65538 19.4167 8.92058 20.4412C8.4797 21.0267 7.30403 21.6121 6.27531 20.5876L5.2466 21.6121C4.54119 22.3146 3.67905 21.9048 3.33616 21.6121L2.45441 20.7339C1.63143 19.9143 2.1115 19.0264 2.45441 18.6849L10.0963 11.0743C10.0963 11.0743 9.3615 9.90338 9.3615 8.29344C9.3615 4.81767 12.1907 2 15.6807 2C19.1708 2 22 4.81767 22 8.29344ZM15.681 10.4889C16.8984 10.4889 17.8853 9.50601 17.8853 8.29353C17.8853 7.08105 16.8984 6.09814 15.681 6.09814C14.4635 6.09814 13.4766 7.08105 13.4766 8.29353C13.4766 9.50601 14.4635 10.4889 15.681 10.4889Z" fill="#1C274C" />
                              </svg>
                              <span>
                                EFI00{zeroPad(profile.data.user_id, 3)}
                              </span>
                            </div>
                            <CopyToClipboard
                              text={"EFI00" + zeroPad(profile.data.user_id, 3)}
                              onCopy={() =>
                                getSuccessNotificationMessage(
                                  t("merchant_id_copied_to_clipboard")
                                )
                              }
                            >
                              <Button className="copy-btn">
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#5C9EEB"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                </svg> */}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0092 7.28671C11.9771 7.07162 13.139 7 14.5 7C15.861 7 17.0229 7.07162 17.9908 7.28671C18.9699 7.50433 19.8106 7.88186 20.4644 8.53561C21.1181 9.18936 21.4957 10.0301 21.7133 11.0092C21.9284 11.9771 22 13.139 22 14.5C22 15.861 21.9284 17.0229 21.7133 17.9908C21.4957 18.9699 21.1181 19.8106 20.4644 20.4644C19.8106 21.1181 18.9699 21.4957 17.9908 21.7133C17.0229 21.9284 15.861 22 14.5 22C13.139 22 11.9771 21.9284 11.0092 21.7133C10.0301 21.4957 9.18936 21.1181 8.53561 20.4644C7.88186 19.8106 7.50433 18.9699 7.28671 17.9908C7.07162 17.0229 7 15.861 7 14.5C7 13.139 7.07162 11.9771 7.28671 11.0092C7.50433 10.0301 7.88186 9.18936 8.53561 8.53561C9.18936 7.88186 10.0301 7.50433 11.0092 7.28671Z" fill="#878787" />
                                  <path d="M6.08776 16.7303C6.02331 16.0357 6 15.2899 6 14.5C6 13.116 6.07156 11.8676 6.31053 10.7923C6.55451 9.69447 6.99883 8.65819 7.82851 7.82851C8.65819 6.99883 9.69447 6.55451 10.7923 6.31053C11.8676 6.07156 13.116 6 14.5 6C15.2899 6 16.0357 6.02331 16.7303 6.08776C16.4704 4.86356 15.9749 3.87161 15.0472 3.17348C13.7659 2.20926 11.886 2 9.5 2C8.13899 2 6.97706 2.07162 6.00924 2.28671C5.03006 2.50433 4.18936 2.88186 3.53561 3.53561C2.88186 4.18936 2.50433 5.03006 2.28671 6.00924C2.07162 6.97706 2 8.13899 2 9.5C2 11.886 2.20926 13.7659 3.17348 15.0472C3.87161 15.9749 4.86356 16.4704 6.08776 16.7303Z" fill="#878787" />
                                </svg>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {changeUserName && (
        <ChangeUserNameModal
          profile={profile}
          changeUserName={changeUserName}
          closeChangeUserNameModal={closeChangeUserNameModal}
          setChangeUserName={setChangeUserName}
        />
      )}
      {changePassword && (
        <ChangePasswordModal
          profile={profile}
          changePassword={changePassword}
          closeChangePasswordModal={closeChangePasswordModal}
          setChangePassword={setChangePassword}
        />
      )}
      {changeMobileNumber && (
        <ChangeMobileNumberModal
          profile={profile}
          changeMobileNumber={changeMobileNumber}
          closeChangeMobileNumberModal={closeChangeMobileNumberModal}
          setchangeMobileNumber={setchangeMobileNumber}
        />
      )}

      {changeEmailAddress && (
        <ChangeEmailAddressModal
          profile={profile}
          setNewEmail={setNewEmail}
          changeEmailAddress={changeEmailAddress}
          closeChangeEmailAddressModal={closeChangeEmailAddressModal}
          setChangeEmailAddress={setChangeEmailAddress}
        />
      )}
      {deleteAccount && (
        <DeleteAccountModal
          deleteAccount={deleteAccount}
          closeDeleteAccountModal={closeDeleteAccountModal}
          setDeleteAccount={setDeleteAccount}
        />
      )}
      {apiKeyModal && (
        <ApiKeyModal
          apiKeyModal={apiKeyModal}
          closeApiKeyModal={closeApiKeyModal}
          setApiKeyModal={setApiKeyModal}
        />
      )}
      {verifyKYC && (
        <VerifyKYCModal
          verifyKYC={verifyKYC}
          closeVerifyKYCModal={closeVerifyKYCModal}
          setVerifyKYC={setVerifyKYC}
        />
      )}
      {showVerify && (
        <OTPVerificationModal
          forgotPassword={2}
          showVerify={showVerify}
          closeShowVerify={() => setShowVerify(false)}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default AccountIndex;
