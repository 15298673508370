import React from 'react';
import { Image } from "react-bootstrap";

const PageLoader = ({ main = false }) => {
  return (
   <div className="page-loader-sec">
     <Image
        className="page-loader-img"
        src={window.location.origin + "/img/page-loader.gif"}
      />
   </div>
  );
}

export default PageLoader;