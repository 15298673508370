import React, { useEffect, useState } from "react";
import { Table, Modal, Image, Button } from "react-bootstrap";
import FsLightbox from "fslightbox-react";
import { useTranslation } from "react-multi-lang";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";

const ViewTransactionModal = (props) => {

  const t = useTranslation("transactions.view")
  const [toggler, setToggler] = useState(false);

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-initiated";
      case 1:
        return "payment-paid";
      case 2:
        return "payment-onhold";
      case 3:
        return "payment-rejected";
      case 4:
        return "payment-cancelled";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#5C9EEB";
      case 1:
        return "#197E23";
      case 2:
        return "#FFCE22";
      case 3:
        return "#FF6A6A";
      case 4:
        return "#FF8A00";
      default:
        return "#5C9EEB";
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-transaction-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Transaction Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="view-transaction-table">
          <Table>
            <tbody>
              <tr>
                <td>{t("transaction_id")}</td>
                <td>{props.show.reference_id}</td>
              </tr>
              <tr>
                <td>{t("order_id")}</td>
                <td>{props.show.order_id}</td>
              </tr>
              <tr>
                <td>{t("payment_id")}</td>
                <td>{props.show.payment_id}</td>
              </tr>
              <tr>
                <td>{t("payment_mode")}</td>
                <td>{props.show.mode}</td>
              </tr>
              {props.show.payment_type == "inr" && (
                <tr>
                  <td>{t("inr_type")}</td>
                  <td>{props.show.inr_type_formatted}</td>
                </tr>
              )}
              {/* <tr>
                <td>{t("type")}</td>
                <td>{props.show.type_formatted}</td>
              </tr> */}
              <tr>
                <td>{t("amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.amount}{" "}{props.show.currency_symbol}
                  </div>
                </td>
              </tr>
              {/* <tr>
                <td>{t("total")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.formatted_total}
                  </div>
                </td>
              </tr> */}
              {/* <tr>
                <td>{t("merchant_commission_amount")}</td>
                <td>
                  <div className="transaction-amount">
                    {props.show.merchant_commission_amount_formatted}
                  </div>
                </td>
              </tr> */}
              <tr>
              <td>{t("admin_commission_amount")}</td>
              <td>
                <div className="transaction-amount">
                  {props.show.admin_commission_amount}
                </div>
              </td>
              </tr>
              {props.show.callback_url && <tr>
                <td>{t("callback_url")}</td>
                <td style={{display:"flex", gap:"1em", justifyContent:"flex-end", alignItems:"center"}}>
                  {props.show.callback_url.length > 20 ? props.show.callback_url.substring(0, 20) + "..." : props.show.callback_url}
                    <CopyToClipboard
                      text={props.show.callback_url}
                      onCopy={() => getSuccessNotificationMessage(t("callback_copied"))}>
                      <Button variant="link" className="copy-btn">
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          fill="#5C9EEB"
                          height="20"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                        </svg> */}
                         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0092 7.28671C11.9771 7.07162 13.139 7 14.5 7C15.861 7 17.0229 7.07162 17.9908 7.28671C18.9699 7.50433 19.8106 7.88186 20.4644 8.53561C21.1181 9.18936 21.4957 10.0301 21.7133 11.0092C21.9284 11.9771 22 13.139 22 14.5C22 15.861 21.9284 17.0229 21.7133 17.9908C21.4957 18.9699 21.1181 19.8106 20.4644 20.4644C19.8106 21.1181 18.9699 21.4957 17.9908 21.7133C17.0229 21.9284 15.861 22 14.5 22C13.139 22 11.9771 21.9284 11.0092 21.7133C10.0301 21.4957 9.18936 21.1181 8.53561 20.4644C7.88186 19.8106 7.50433 18.9699 7.28671 17.9908C7.07162 17.0229 7 15.861 7 14.5C7 13.139 7.07162 11.9771 7.28671 11.0092C7.50433 10.0301 7.88186 9.18936 8.53561 8.53561C9.18936 7.88186 10.0301 7.50433 11.0092 7.28671Z" fill="#878787" />
                            <path d="M6.08776 16.7303C6.02331 16.0357 6 15.2899 6 14.5C6 13.116 6.07156 11.8676 6.31053 10.7923C6.55451 9.69447 6.99883 8.65819 7.82851 7.82851C8.65819 6.99883 9.69447 6.55451 10.7923 6.31053C11.8676 6.07156 13.116 6 14.5 6C15.2899 6 16.0357 6.02331 16.7303 6.08776C16.4704 4.86356 15.9749 3.87161 15.0472 3.17348C13.7659 2.20926 11.886 2 9.5 2C8.13899 2 6.97706 2.07162 6.00924 2.28671C5.03006 2.50433 4.18936 2.88186 3.53561 3.53561C2.88186 4.18936 2.50433 5.03006 2.28671 6.00924C2.07162 6.97706 2 8.13899 2 9.5C2 11.886 2.20926 13.7659 3.17348 15.0472C3.87161 15.9749 4.86356 16.4704 6.08776 16.7303Z" fill="#878787" />
                          </svg>
                      </Button>
                    </CopyToClipboard>
                </td>
              </tr>}
              {props.show.user_upi_id && (
                <tr>
                  <td>{t("user_upi_id")}</td>
                  <td>{props.show.user_upi_id}</td>
                </tr>
              )}
              {props.show.utr_number && (
                <tr>
                  <td>{t("utr_number")}</td>
                  <td>{props.show.utr_number}</td>
                </tr>
              )}
              {props.show.user_email && (
                <tr>
                  <td>{t("user_email")}</td>
                  <td>{props.show.user_email}</td>
                </tr>
              )}
              <tr>
                <td>{t("status")}</td>
                <td style={{display:"flex", justifyContent:"flex-end"}}>
                  <div className={getStatusClass(props.show.status_formatted)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="#5C9EEB"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                    </svg>
                    {props.show.status}
                  </div>
                </td>
              </tr>
              {props.show.cancel_reason && (
                <tr>
                  <td>{t("cancel_reason")}</td>
                  <td>{props.show.cancel_reason}</td>
                </tr>
              )}
              {props.show.payment_picture && (
                <tr>
                  <td>{t("screen_shot")}</td>
                  <td>
                    {/* <button
                      className="view-screen-shots"
                      onClick={() => setToggler(!toggler)}
                    >
                      {t("view_screen_shot")}
                    </button>
                    <FsLightbox
                      toggler={toggler}
                      sources={[props.show.payment_picture, props.show.payment_picture]}
                    /> */}
                    <a style={{ textDecoration:'none' }} className="view-screen-shots" href={props.show.payment_picture} target="_blank" rel="noreferrer">
                      {t("view_screen_shot")}
                    </a>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTransactionModal;
