import React, { useEffect, useState } from "react";
import { Button, Image, Form, InputGroup, Row, Col } from "react-bootstrap";
import ChangeMobileNumberModal from "./ChangeMobileNumberModal";
import ChangeUserNameModal from "./ChangeUserNameModal";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeEmailAddressModal from "./ChangeEmailAddressModal";
import DeleteAccountModal from "./DeleteAccountModal";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import {
    profileStart,
    updateProfileStart,
} from "../../store/slices/AdminSlice";
import useLogout from "../../hooks/useLogout";
import VerifyKYCModal from "./VerifyKYCModal";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import OTPVerificationModal from "../Auth/OTPVerificationModal";
import ApiKeyModal from "./ApiKeyModal";
import { useTranslation } from "react-multi-lang";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";

const ProfileSettings = (props) => {
    const dispatch = useDispatch();
    const { logout } = useLogout();
    const t = useTranslation("account_settings");
    const profile = useSelector((state) => state.admin.profile);
    const updateProfile = useSelector((state) => state.admin.updateProfile);
    const kycStatusUpdate = useSelector((state) => state.admin.kycStatusUpdate);
    const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
    const [changeUserName, setChangeUserName] = useState(false);
    const [changeMobileNumber, setchangeMobileNumber] = useState(false);
    const [skipRender, setSkipRender] = useState(true);
    const [showVerify, setShowVerify] = useState(false);
    const [newEmail, setNewEmail] = useState();

    const closeChangeUserNameModal = () => {
        setChangeUserName(false);
    };
    const closeChangeMobileNumberModal = () => {
        setchangeMobileNumber(false);
    };

    const [changePassword, setChangePassword] = useState(false);

    const closeChangePasswordModal = () => {
        setChangePassword(false);
    };

    const [changeEmailAddress, setChangeEmailAddress] = useState(false);

    const closeChangeEmailAddressModal = () => {
        setChangeEmailAddress(false);
    };

    const [deleteAccount, setDeleteAccount] = useState(false);

    const closeDeleteAccountModal = () => {
        setDeleteAccount(false);
    };

    const [verifyKYC, setVerifyKYC] = useState(false);

    const closeVerifyKYCModal = () => {
        setVerifyKYC(false);
    };

    const [apiKeyModal, setApiKeyModal] = useState(false);

    const closeApiKeyModal = () => {
        setApiKeyModal(false);
    };

    const handleUpdateProfilePicture = (image) => {
        image &&
            dispatch(
                updateProfileStart({
                    picture: image,
                    name: profile.data.name,
                    email: profile.data.email,
                    mobile: profile.data.mobile,
                    country_code: profile.data.country_code,
                    citizenship: profile.data.citizenship,
                    residence: profile.data.residence,
                })
            );
    };

    useEffect(() => {
        if (
            !skipRender &&
            !kycStatusUpdate.loading &&
            Object.keys(kycStatusUpdate.data).length > 0
        ) {
            closeVerifyKYCModal();
        }
        setSkipRender(false);
    }, [kycStatusUpdate]);

    useEffect(() => {
        if (
            !skipRender &&
            !updateEmailCode.loading &&
            Object.keys(updateEmailCode.data).length > 0
        ) {
            setShowVerify(newEmail);
        }
        setSkipRender(false);
    }, [updateEmailCode]);

    const zeroPad = (num, places) => String(num).padStart(places, "0");

    return profile.loading ? (
        <PageLoader />
    ) : Object.keys(profile.data).length > 0 ? (
        <>
            <div className="profile-account-sec">
                <div className="settlement-top-sec profile-setting-frame-top">
                    <h3>{t("heading")}</h3>
                    <div className="profile-action-frame-wrap">
                        <div className="profile-left-action-area">
                            <Button className="efi-green-btn" onClick={logout}>
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path d="M160,256a16,16,0,0,1,16-16H320V136c0-32-33.79-56-64-56H104a56.06,56.06,0,0,0-56,56V376a56.06,56.06,0,0,0,56,56H264a56.06,56.06,0,0,0,56-56V272H176A16,16,0,0,1,160,256Z"></path><path d="M459.31,244.69l-80-80a16,16,0,0,0-22.62,22.62L409.37,240H320v32h89.37l-52.68,52.69a16,16,0,1,0,22.62,22.62l80-80a16,16,0,0,0,0-22.62Z"></path></svg> */}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 9.56757V14.4324C3.5 16.7258 3.5 17.8724 4.22161 18.5849C4.87719 19.2321 5.89578 19.2913 7.81846 19.2968C7.71686 18.6224 7.69563 17.8168 7.69029 16.8689C7.68802 16.4659 8.01709 16.1374 8.42529 16.1351C8.83348 16.1329 9.16624 16.4578 9.16851 16.8608C9.17451 17.9247 9.20249 18.6789 9.30898 19.2512C9.41158 19.8027 9.57634 20.1219 9.81626 20.3588C10.089 20.6281 10.4719 20.8037 11.1951 20.8996C11.9395 20.9985 12.9261 21 14.3407 21H15.3262C16.7407 21 17.7273 20.9985 18.4717 20.8996C19.1949 20.8037 19.5778 20.6281 19.8505 20.3588C20.1233 20.0895 20.3011 19.7114 20.3983 18.9975C20.4984 18.2626 20.5 17.2885 20.5 15.8919V8.10811C20.5 6.71149 20.4984 5.73743 20.3983 5.0025C20.3011 4.28855 20.1233 3.91048 19.8505 3.6412C19.5778 3.37192 19.1949 3.19635 18.4717 3.10036C17.7273 3.00155 16.7407 3 15.3262 3H14.3407C12.9261 3 11.9395 3.00155 11.1951 3.10036C10.4719 3.19635 10.089 3.37192 9.81626 3.6412C9.57634 3.87807 9.41158 4.19728 9.30898 4.74877C9.20249 5.32112 9.17451 6.07525 9.16851 7.1392C9.16624 7.54221 8.83348 7.8671 8.42529 7.86485C8.01709 7.86261 7.68802 7.53409 7.69029 7.13107C7.69563 6.18322 7.71686 5.37758 7.81846 4.70325C5.89578 4.70867 4.87719 4.76789 4.22161 5.41515C3.5 6.12759 3.5 7.27425 3.5 9.56757ZM5.93385 12.516C5.6452 12.231 5.6452 11.769 5.93385 11.484L7.90484 9.53806C8.19348 9.25308 8.66147 9.25308 8.95011 9.53806C9.23876 9.82304 9.23876 10.2851 8.95011 10.5701L8.24088 11.2703L15.3259 11.2703C15.7341 11.2703 16.0651 11.597 16.0651 12C16.0651 12.403 15.7341 12.7297 15.3259 12.7297L8.24088 12.7297L8.95011 13.4299C9.23876 13.7149 9.23876 14.177 8.95011 14.4619C8.66147 14.7469 8.19348 14.7469 7.90484 14.4619L5.93385 12.516Z" fill="#5ac15b" />
                                </svg>
                            </Button>
                            <Button
                                className="efi-danger-btn"
                                onClick={() => setDeleteAccount(true)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    data-name="Layer 1"
                                    viewBox="0 0 512 512"
                                >
                                    <path
                                        fill="#fc0005"
                                        fillRule="evenodd"
                                        d="M170.8 14.221A14.21 14.21 0 01185 .014L326.991.006a14.233 14.233 0 0114.2 14.223v35.117H170.8zm233.461 477.443a21.75 21.75 0 01-21.856 20.33H127.954a21.968 21.968 0 01-21.854-20.416L84.326 173.06H427.5l-23.234 318.6zm56.568-347.452H51.171v-33A33.035 33.035 0 0184.176 78.2l343.644-.011a33.051 33.051 0 0133 33.02v33zm-270.79 291.851a14.422 14.422 0 1028.844 0V233.816a14.42 14.42 0 00-28.839-.01v202.257zm102.9 0a14.424 14.424 0 1028.848 0V233.816a14.422 14.422 0 00-28.843-.01z"
                                    ></path>
                                </svg>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="profile-account-box">
                    <div className="profile-account-header-sec">
                        <div className="profile-account-header-left-sec">
                            <div className="profile-account-user-details-sec">
                                <div className="profile-account-user-img-sec">
                                    <CustomLazyLoad
                                        className="profile-account-user-img"
                                        src={
                                            profile.data.picture ||
                                            window.location.origin + "/img/user-img.svg"
                                        }
                                        type="image/png"
                                    />
                                </div>
                                <div className="profile-account-user-info">
                                    <label className="profile-account-user-img-edit-icon">
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg,, image/jpg"
                                            size="60"
                                            disabled={updateProfile.buttonDisable}
                                            onChange={(e) =>
                                                handleUpdateProfilePicture(e.target.files[0])
                                            }
                                        />
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            data-name="Layer 3"
                                            viewBox="0 0 32 32"
                                            fill="#fff"
                                        >
                                            <path d="M28.071 13.127a1.5 1.5 0 00-1.5 1.5V23.9a2.605 2.605 0 01-2.6 2.6H8.1a2.606 2.606 0 01-2.6-2.6V8.031a2.605 2.605 0 012.6-2.6h9.27a1.5 1.5 0 000-3H8.1a5.609 5.609 0 00-5.6 5.6V23.9a5.61 5.61 0 005.6 5.6h15.869a5.609 5.609 0 005.6-5.6v-9.27a1.5 1.5 0 00-1.498-1.503zM29.707 6.472l-4.179-4.179a1.029 1.029 0 00-1.414 0L22.21 4.2l5.59 5.59 1.905-1.9a1 1 0 000-1.414z"></path>
                                            <path d="M11.286 15.536v4.178a1 1 0 001 1h4.178a1 1 0 00.707-.293l9.217-9.221L20.8 5.612l-9.217 9.217a1 1 0 00-.297.707z"></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="profile-left-wrap-area">
                            {profile.data.kyc_status == 3 ? (
                                <div className="profile-account-header-right-sec">
                                    <h5>{t("kyc_verified")}</h5>
                                    <div className="profile-account-header-kyc-verified-btn">
                                        <Image
                                            className="kyc-verified-icon"
                                            src={window.location.origin + "/img/kyc-verified.svg"}
                                            type="image/png"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="profile-account-header-kyc-verified-btn">
                                    <Button
                                        className="efi-auth-btn"
                                        onClick={() => setVerifyKYC(true)}
                                    >
                                        {t("verify_kyc")}
                                    </Button>
                                </div>
                            )}

                        </div>
                    </div>
                    <div className="profile-account-body-sec">
                        <div className="profile-account-list-box">
                            <div className="profile-details-info-card-wrap">
                                <div className="settlement-top-sec">
                                    <h3>Profile Info</h3>
                                </div>
                                <div className="profile-new-frame">
                                    <div className="profile-input-wrap profile-form-frame">
                                        <Row className="mb-3">
                                            <Form.Group as={Col}>
                                                <Form.Label>{t("username")}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        placeholder={profile.data.name}
                                                        aria-describedby="basic-addon2"
                                                        disabled
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        <Button
                                                            className="efi-auth-btn"
                                                            onClick={() => setChangeUserName(true)}
                                                        >
                                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C12 20.4477 12.4477 20 13 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H13C12.4477 22 12 21.5523 12 21Z" fill="#252525B2" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7736 8.09994C22.3834 6.48381 22.315 4.36152 21.113 3.06183C20.5268 2.4281 19.6926 2.0233 18.7477 2.00098C17.7993 1.97858 16.8167 2.34127 15.91 3.09985C15.8868 3.11925 15.8645 3.13969 15.8432 3.16111L2.87446 16.1816C2.31443 16.7438 2 17.5051 2 18.2987V19.9922C2 21.0937 2.89197 22 4.00383 22H5.68265C6.48037 22 7.24524 21.6823 7.80819 21.1171L20.7736 8.09994ZM17.2071 5.79295C16.8166 5.40243 16.1834 5.40243 15.7929 5.79295C15.4024 6.18348 15.4024 6.81664 15.7929 7.20717L16.7929 8.20717C17.1834 8.59769 17.8166 8.59769 18.2071 8.20717C18.5976 7.81664 18.5976 7.18348 18.2071 6.79295L17.2071 5.79295Z" fill="#252525B2" />
                                                            </svg>
                                                            {t("edit")}
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>{t("change_email_address")}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        placeholder={profile.data.email}
                                                        aria-describedby="basic-addon2"
                                                        disabled
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        <Button
                                                            className="efi-auth-btn"
                                                            onClick={() => setChangeEmailAddress(true)}
                                                        >
                                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C12 20.4477 12.4477 20 13 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H13C12.4477 22 12 21.5523 12 21Z" fill="#252525B2" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7736 8.09994C22.3834 6.48381 22.315 4.36152 21.113 3.06183C20.5268 2.4281 19.6926 2.0233 18.7477 2.00098C17.7993 1.97858 16.8167 2.34127 15.91 3.09985C15.8868 3.11925 15.8645 3.13969 15.8432 3.16111L2.87446 16.1816C2.31443 16.7438 2 17.5051 2 18.2987V19.9922C2 21.0937 2.89197 22 4.00383 22H5.68265C6.48037 22 7.24524 21.6823 7.80819 21.1171L20.7736 8.09994ZM17.2071 5.79295C16.8166 5.40243 16.1834 5.40243 15.7929 5.79295C15.4024 6.18348 15.4024 6.81664 15.7929 7.20717L16.7929 8.20717C17.1834 8.59769 17.8166 8.59769 18.2071 8.20717C18.5976 7.81664 18.5976 7.18348 18.2071 6.79295L17.2071 5.79295Z" fill="#252525B2" />
                                                            </svg>
                                                            {t("edit")}
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col}>
                                                <Form.Label>Change Number</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        placeholder={"+" + profile.data.country_code + " " + profile.data.mobile}
                                                        aria-describedby="basic-addon2"
                                                        disabled
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        <Button
                                                            className="efi-auth-btn"
                                                            onClick={() => setchangeMobileNumber(true)}
                                                        >
                                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C12 20.4477 12.4477 20 13 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H13C12.4477 22 12 21.5523 12 21Z" fill="#252525B2" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7736 8.09994C22.3834 6.48381 22.315 4.36152 21.113 3.06183C20.5268 2.4281 19.6926 2.0233 18.7477 2.00098C17.7993 1.97858 16.8167 2.34127 15.91 3.09985C15.8868 3.11925 15.8645 3.13969 15.8432 3.16111L2.87446 16.1816C2.31443 16.7438 2 17.5051 2 18.2987V19.9922C2 21.0937 2.89197 22 4.00383 22H5.68265C6.48037 22 7.24524 21.6823 7.80819 21.1171L20.7736 8.09994ZM17.2071 5.79295C16.8166 5.40243 16.1834 5.40243 15.7929 5.79295C15.4024 6.18348 15.4024 6.81664 15.7929 7.20717L16.7929 8.20717C17.1834 8.59769 17.8166 8.59769 18.2071 8.20717C18.5976 7.81664 18.5976 7.18348 18.2071 6.79295L17.2071 5.79295Z" fill="#252525B2" />
                                                            </svg>
                                                            {t("edit")}
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col}>
                                                <Form.Label>{t("change_password")}</Form.Label>
                                                <InputGroup>
                                                    <Form.Control
                                                        placeholder="************"
                                                        aria-describedby="basic-addon2"
                                                        disabled
                                                    />
                                                    <InputGroup.Text id="basic-addon2">
                                                        <Button
                                                            className="efi-auth-btn"
                                                            onClick={() => setChangePassword(true)}
                                                        >
                                                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C12 20.4477 12.4477 20 13 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H13C12.4477 22 12 21.5523 12 21Z" fill="#252525B2" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7736 8.09994C22.3834 6.48381 22.315 4.36152 21.113 3.06183C20.5268 2.4281 19.6926 2.0233 18.7477 2.00098C17.7993 1.97858 16.8167 2.34127 15.91 3.09985C15.8868 3.11925 15.8645 3.13969 15.8432 3.16111L2.87446 16.1816C2.31443 16.7438 2 17.5051 2 18.2987V19.9922C2 21.0937 2.89197 22 4.00383 22H5.68265C6.48037 22 7.24524 21.6823 7.80819 21.1171L20.7736 8.09994ZM17.2071 5.79295C16.8166 5.40243 16.1834 5.40243 15.7929 5.79295C15.4024 6.18348 15.4024 6.81664 15.7929 7.20717L16.7929 8.20717C17.1834 8.59769 17.8166 8.59769 18.2071 8.20717C18.5976 7.81664 18.5976 7.18348 18.2071 6.79295L17.2071 5.79295Z" fill="#252525B2" />
                                                            </svg>
                                                            {t("edit")}
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="profile-details-info-card-wrap">
                <div className="settlement-top-sec">
                  <h3>Other Info</h3>
                </div>
                <div className="profile-new-frame">
                  <div className="profile-input-wrap profile-form-frame">
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>{t("api_key")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="************"
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setApiKeyModal(1)}
                            >
                              {t("view")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>{t("salt_key")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="************"
                            aria-describedby="basic-addon2"
                            disabled
                          />
                          <InputGroup.Text id="basic-addon2">
                            <Button
                              className="efi-auth-btn"
                              onClick={() => setApiKeyModal(2)}
                            >
                              {t("view")}
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label>{t("merchant_id")}</Form.Label>
                        <InputGroup>
                          <div className="merchet-key-new-card">
                            <div className="payment-link-created-left-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                width={16}
                                height={16}
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                                />
                              </svg>
                              <span>
                                EFI00{zeroPad(profile.data.user_id, 3)}
                              </span>
                            </div>
                            <CopyToClipboard
                              text={"EFI00" + zeroPad(profile.data.user_id, 3)}
                              onCopy={() =>
                                getSuccessNotificationMessage(
                                  t("merchant_id_copied_to_clipboard")
                                )
                              }
                            >
                              <Button className="copy-btn">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  fill="#5C9EEB"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0022 15.548V5.452A3.456 3.456 0 0018.548 2H9.452A3.456 3.456 0 006 5.452V6h-.548A3.456 3.456 0 002 9.452v9.096A3.456 3.456 0 005.452 22zM8 5.452C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452v10.096c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0014.548 6H8zm-4 4C4 8.652 4.651 8 5.452 8h9.096c.8 0 1.452.651 1.452 1.452v9.096c0 .8-.651 1.452-1.452 1.452H5.452C4.652 20 4 19.349 4 18.548z"></path>
                                </svg>
                              </Button>
                            </CopyToClipboard>
                          </div>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                  </div>
                </div>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {changeUserName && (
                <ChangeUserNameModal
                    profile={profile}
                    changeUserName={changeUserName}
                    closeChangeUserNameModal={closeChangeUserNameModal}
                    setChangeUserName={setChangeUserName}
                />
            )}
            {changePassword && (
                <ChangePasswordModal
                    profile={profile}
                    changePassword={changePassword}
                    closeChangePasswordModal={closeChangePasswordModal}
                    setChangePassword={setChangePassword}
                />
            )}
            {changeMobileNumber && (
                <ChangeMobileNumberModal
                    profile={profile}
                    changeMobileNumber={changeMobileNumber}
                    closeChangeMobileNumberModal={closeChangeMobileNumberModal}
                    setchangeMobileNumber={setchangeMobileNumber}
                />
            )}

            {changeEmailAddress && (
                <ChangeEmailAddressModal
                    profile={profile}
                    setNewEmail={setNewEmail}
                    changeEmailAddress={changeEmailAddress}
                    closeChangeEmailAddressModal={closeChangeEmailAddressModal}
                    setChangeEmailAddress={setChangeEmailAddress}
                />
            )}
            {deleteAccount && (
                <DeleteAccountModal
                    deleteAccount={deleteAccount}
                    closeDeleteAccountModal={closeDeleteAccountModal}
                    setDeleteAccount={setDeleteAccount}
                />
            )}
            {apiKeyModal && (
                <ApiKeyModal
                    apiKeyModal={apiKeyModal}
                    closeApiKeyModal={closeApiKeyModal}
                    setApiKeyModal={setApiKeyModal}
                />
            )}
            {verifyKYC && (
                <VerifyKYCModal
                    verifyKYC={verifyKYC}
                    closeVerifyKYCModal={closeVerifyKYCModal}
                    setVerifyKYC={setVerifyKYC}
                />
            )}
            {showVerify && (
                <OTPVerificationModal
                    forgotPassword={2}
                    showVerify={showVerify}
                    closeShowVerify={() => setShowVerify(false)}
                />
            )}
        </>
    ) : (
        <SomethingWentWrong
            buttonText="Retry"
            handleClick={() => dispatch(profileStart())}
        />
    );
};

export default ProfileSettings;
