import React from "react";
import { Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import { useDispatch, useSelector } from "react-redux";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import useLogout from "../../../hooks/useLogout";

const CollapseSidebar = (props) => {
  const t = useTranslation("collaspe_sidebar")
  const { logout } = useLogout();
  const location = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);

  return (
    <>
      <div className={`admin-ui-sidebar admin-ui-collapse-sidebar`}>
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-overlay-hidden"></div>
          <div className="admin-ui-logo-sec">
            <div className="admin-user-details">
              <Image
                className="logo"
                src={configuration.get("configData.site_logo")}
              />
              <Button
                className="offcanvas-close"
                onClick={() => dispatch(toggleSidebar())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="#3D9F99"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.341 9.48l-3.501-6A5.016 5.016 0 0015.522 1H8.451a5.02 5.02 0 00-4.319 2.48l-3.501 6a5.01 5.01 0 000 5.039l3.501 6a5.016 5.016 0 004.318 2.48h7.071a5.02 5.02 0 004.319-2.48l3.5-6a5.01 5.01 0 000-5.039zm-1.729 4.031l-3.499 6a3.011 3.011 0 01-2.592 1.488H8.45a3.01 3.01 0 01-2.591-1.488l-3.5-6a3.004 3.004 0 010-3.023l3.499-6A3.011 3.011 0 018.45 3h7.071a3.01 3.01 0 012.591 1.488l3.5 6a3.004 3.004 0 010 3.023zm-5.905-3.805l-2.293 2.293 2.293 2.293a.999.999 0 11-1.414 1.414L12 13.413l-2.293 2.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.293-2.293-2.293-2.293a.999.999 0 111.414-1.414L12 10.585l2.293-2.293a.999.999 0 111.414 1.414z"></path>
                </svg>
              </Button>
            </div>
          </div>
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled">
              <li>
                <Link
                  to="/"
                  className={`${location.pathname === "/" && "active"}`}
                >
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="#111"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.667"
                      d="M17.5 12.841v2.992a1.666 1.666 0 01-1.666 1.667h-2.992a1.175 1.175 0 01-.834-2.009l3.476-3.475a1.176 1.176 0 012.016.825zM5.417 8.333a2.917 2.917 0 100-5.833 2.917 2.917 0 000 5.833zM15.833 2.5h-2.5c-.92 0-1.666.746-1.666 1.667v2.5c0 .92.746 1.666 1.666 1.666h2.5c.92 0 1.667-.746 1.667-1.666v-2.5c0-.92-.746-1.667-1.667-1.667zM6.667 11.667h-2.5c-.92 0-1.667.746-1.667 1.666v2.5c0 .92.746 1.667 1.667 1.667h2.5c.92 0 1.666-.746 1.666-1.667v-2.5c0-.92-.746-1.666-1.666-1.666z"
                    ></path>
                  </svg> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z"></path></svg>
                  <span>{t("dashboard")}</span>
                </Link>
              </li>
              {profile.data.kyc_status == 3 && (
                <React.Fragment>
                  <li>
                    <Link
                      to="/transactions"
                      className={`${location.pathname.includes(
                        "transactions"
                      ) && "active"
                        }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 100 100"
                      >
                        <g>
                          <path d="M66.071 69.973v9.17L92.5 57.163H15.283v11.741h49.718c.591 0 1.07.478 1.07 1.069zM84.717 31.097H34.999a1.07 1.07 0 01-1.07-1.07v-9.171L7.5 42.837h77.217z"></path>
                        </g>
                      </svg>
                      <span>{t("payin_transactions")}</span>
                    </Link>
                  </li>
                </React.Fragment>
              )}
            </ul>
            <ul className="admin-ui-sidebar-list list-unstyled">
                {profile.data.kyc_status == 3 && (
                  <React.Fragment>
                    <li>
                      <Link
                        to="/account-settings"
                        className={`${location.pathname.includes("account-settings") &&
                          "active"
                          }`}
                      >
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-name="Layer 1"
                          viewBox="0 0 512 512"
                          width="24"
                          height="24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M480.235 295.764c-47.263 0-70.976 57.258-37.558 90.675a15.767 15.767 0 010 22.3l-33.941 33.942a15.77 15.77 0 01-22.3 0c-33.417-33.418-90.675-9.705-90.675 37.558A15.764 15.764 0 01280 496h-48a15.764 15.764 0 01-15.765-15.765c0-47.321-57.215-71.018-90.675-37.558a15.769 15.769 0 01-22.3 0l-33.937-33.942a15.769 15.769 0 010-22.3c33.418-33.417 9.705-90.675-37.558-90.675A15.764 15.764 0 0116 280v-48a15.764 15.764 0 0115.765-15.765c47.263 0 70.976-57.258 37.558-90.675a15.769 15.769 0 010-22.3l33.942-33.942a15.767 15.767 0 0122.3 0c33.417 33.418 90.675 9.705 90.675-37.558A15.764 15.764 0 01232 16h48a15.764 15.764 0 0115.765 15.765c0 47.263 57.258 70.976 90.675 37.558a15.768 15.768 0 0122.3 0l33.941 33.942a15.767 15.767 0 010 22.3c-33.418 33.417-9.705 90.675 37.558 90.675A15.764 15.764 0 01496 232v48a15.764 15.764 0 01-15.765 15.765zm-77.27-39.809A146.965 146.965 0 10256 402.965a146.966 146.966 0 00146.965-147.01zM206.282 211.2A49.721 49.721 0 10256 161.485a49.719 49.719 0 00-49.718 49.715zM256 289.573a107.576 107.576 0 00-95.815 58.62 132.961 132.961 0 00191.624 0A107.563 107.563 0 00256 289.573z"
                          ></path>
                        </svg> */}
                        <svg width="25"
                          height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.6,3.32a3.86,3.86,0,1,0,3.86,3.85A3.85,3.85,0,0,0,9.6,3.32M16.35,11a.26.26,0,0,0-.25.21l-.18,1.27a4.63,4.63,0,0,0-.82.45l-1.2-.48a.3.3,0,0,0-.3.13l-1,1.66a.24.24,0,0,0,.06.31l1,.79a3.94,3.94,0,0,0,0,1l-1,.79a.23.23,0,0,0-.06.3l1,1.67c.06.13.19.13.3.13l1.2-.49a3.85,3.85,0,0,0,.82.46l.18,1.27a.24.24,0,0,0,.25.2h1.93a.24.24,0,0,0,.23-.2l.18-1.27a5,5,0,0,0,.81-.46l1.19.49c.12,0,.25,0,.32-.13l1-1.67a.23.23,0,0,0-.06-.3l-1-.79a4,4,0,0,0,0-.49,2.67,2.67,0,0,0,0-.48l1-.79a.25.25,0,0,0,.06-.31l-1-1.66c-.06-.13-.19-.13-.31-.13L19.5,13a4.07,4.07,0,0,0-.82-.45l-.18-1.27a.23.23,0,0,0-.22-.21H16.46M9.71,13C5.45,13,2,14.7,2,16.83v1.92h9.33a6.65,6.65,0,0,1,0-5.69A13.56,13.56,0,0,0,9.71,13m7.6,1.43a1.45,1.45,0,1,1,0,2.89,1.45,1.45,0,0,1,0-2.89Z" />
                        </svg>
                        <span>{t("account_settings")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/profile-settings"
                        className={`${location.pathname.includes("profile-settings") &&
                          "active"
                          }`}
                      >
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 21 22"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.12.007c.189-.01.38-.01.57 0 .733.036 1.408.25 2.16.599.733.34 1.585.831 2.662 1.453l.06.036c1.078.621 1.93 1.113 2.59 1.578.68.477 1.201.955 1.6 1.572.103.16.198.324.285.494.335.653.488 1.344.562 2.17.072.805.072 1.788.072 3.032v.07c0 1.245 0 2.228-.072 3.032-.074.827-.227 1.518-.562 2.171-.087.17-.182.334-.286.494-.485.752-1.156 1.3-2.07 1.888-.579.374-1.285.781-2.134 1.271l-.045.027c-1.077.622-1.929 1.113-2.661 1.453-.753.35-1.428.562-2.162.599-.19.01-.38.01-.57 0-.733-.037-1.408-.25-2.161-.599-.733-.34-1.584-.832-2.661-1.453l-.045-.026a55.1 55.1 0 01-2.118-1.26c-.922-.593-1.599-1.144-2.087-1.9a5.755 5.755 0 01-.285-.494C.427 15.561.274 14.87.2 14.044c-.072-.805-.072-1.789-.072-3.032v-.07c0-1.245 0-2.228.072-3.032.074-.827.227-1.518.562-2.171.087-.17.182-.334.285-.494.398-.617.92-1.095 1.6-1.572.66-.465 1.511-.957 2.589-1.578l.06-.036C6.375 1.437 7.227.946 7.959.606 8.71.256 9.386.043 10.119.007zm6.893 17.241a4 4 0 00-3.609-2.272h-6a4 4 0 00-3.608 2.272l.15.098c.542.348 1.212.736 2.07 1.231 1.114.643 1.908 1.1 2.573 1.41.652.302 1.132.437 1.604.46.14.008.282.008.422 0 .473-.023.952-.158 1.604-.46.665-.31 1.459-.767 2.573-1.41.867-.5 1.542-.89 2.087-1.242l.134-.087zM10.404 3.226a4.75 4.75 0 100 9.5 4.75 4.75 0 000-9.5z"
                            clipRule="evenodd"
                          ></path>
                        </svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                        </g>
                      </svg>
                        <span>Profile Settings</span>
                      </Link>
                    </li>
                    <li>
                      <Button onClick={logout}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path d="M160,256a16,16,0,0,1,16-16H320V136c0-32-33.79-56-64-56H104a56.06,56.06,0,0,0-56,56V376a56.06,56.06,0,0,0,56,56H264a56.06,56.06,0,0,0,56-56V272H176A16,16,0,0,1,160,256Z"></path><path d="M459.31,244.69l-80-80a16,16,0,0,0-22.62,22.62L409.37,240H320v32h89.37l-52.68,52.69a16,16,0,1,0,22.62,22.62l80-80a16,16,0,0,0,0-22.62Z"></path></svg>
                        <span>Logout</span>
                      </Button>
                    </li>
                  </React.Fragment>
                )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollapseSidebar;
