import React, { useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";
import NoDataFound from "../Helper/NoDataFound";

const PieGraph = (props) => {
  
  const statusColors = {
    "Initiated": "#5C9EEB",
    "User Paid": "#FFCE22",
    "Success": "#197E23",
    "Rejected": "#ff0000",
    "Cancelled": "#FF8A00",
    "Failed": "#FF6A6A",
    "Inprogress": "#EEE402",
    "ChargeBack": "#DEF123", 
    "Refunded": "#FEE713",
  };

  const data = props.data.status.map((status, index) => ({
    name: status,
    value: props.data.model_data[index],
    fill: statusColors[status] || "#F7F7F7",
  }));
  
  return (
    <>
      <div className="pie-chart-sec">
        {props.count ? <div className="pie-chart-label-sec">
          <div className="label-sec-inner">
            <div className="label-sec-inner-right">
              {props.data.status.map((status, index) => (
                <div className="label-sec-inner-right-item" key={index}>
                  <div className="label-sec-inner-right-item-name">
                    {status}
                  </div>
                  <div
                    className="label-sec-inner-right-item-color"
                    style={{ backgroundColor: statusColors[status] }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div> : null}
        <ResponsiveContainer width="100%" height={400}>
          {props.count ? <PieChart width={730} height={250}>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              fill="#8884d8"
            />
            <Tooltip />
          </PieChart> : <NoDataFound/>}
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PieGraph;
