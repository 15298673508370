import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SomethingWentWrong = ({ buttonText }) => {
  const theme = useSelector(state => state.admin.theme);

  return (
    <div className="grid w-full grow grid-cols-1 place-items-center">
      <div className="max-w-md p-6 text-center">
        <div className="w-full">
          <img className="w-full" src={window.location.origin + "/img/something-went-wrong.svg"} alt="something-2went-wrong" />
        </div>
        <h4 className="pt-4 text-xl font-semibold text-slate-800 dark:text-navy-50 mb-3">
          Oops! Something Went Wrong
        </h4>
        {buttonText ?
          <Button
            style={{margin:"auto"}}
            className="efi-payment-btn"
            onClick={() => window.location.reload()}
          >
            {buttonText}
          </Button>
          : null
        }
      </div>
    </div>
  );
}

export default SomethingWentWrong;