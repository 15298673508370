import AdminSlice from "./AdminSlice";
import CategorySlice from "./CategorySlice";
import CommonSlice from "./CommonSlice";
import PaymentSlice from "./PaymentSlice";

export const reducers = {

    common: CommonSlice,
    admin: AdminSlice,
    category: CategorySlice,
    payment: PaymentSlice,

};