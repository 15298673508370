import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { kycApplicantStart, kycStatusUpdateStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";

const VerifyKYCModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("verify_kyc");
  const kycApplicant = useSelector((state) => state.admin.kycApplicant);
  const kycStatusUpdate = useSelector(state => state.admin.kycStatusUpdate)
  const errorHandler = (data) => console.log("onError", data);
  const accessTokenExpirationHandler = () => {};
  const [skip, setSkip] = useState(false);

  useEffect(()=> {
    dispatch(kycApplicantStart());
  }, [])

  const messageHandler = (type, payload) => {
    if (type == "idCheck.applicantReviewComplete" || payload.reviewStatus == "completed") {
      if (!skip) {
        dispatch(
          kycStatusUpdateStart({
            kyc_status: 3,
          })
        );
      }
    }
    setSkip(true);
  };

  useEffect(()=> {
    if(!skip && !kycStatusUpdate.loading && Object.keys(kycStatusUpdate.data).length > 0)
      props.closeVerifyKYCModal();
    setSkip(false);
  }, [kycStatusUpdate])

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.verifyKYC}
        onHide={props.closeVerifyKYCModal}
      >
        <Modal.Body>
          <Button onClick={props.closeVerifyKYCModal} className="modal-close">
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
            <div className="settlement-top-sec">
              <h3>{t("heading")}</h3>
            </div>
            <Row className="align-items-center">
              <Col md={12}>
                {kycApplicant.loading ? (
                  <div className="kyc-modal-loader-sec">
                    <Skeleton height={300} />
                  </div>
                ) : Object.keys(kycApplicant.data).length > 0 ? (
                  <SumsubWebSdk
                    accessToken={kycApplicant.data.token}
                    expirationHandler={accessTokenExpirationHandler}
                    config={{}}
                    options={{}}
                    onMessage={messageHandler}
                    onError={errorHandler}
                  />
                ) : null}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifyKYCModal;
