import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateEmailCodeStart, updateProfileStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const ChangeEmailAddressModal = (props) => {

  const t = useTranslation("change_email");
  const dispatch = useDispatch();
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("user_email.invalid")).required(t("user_email.required")),
  });

  const handleSubmit = (values) => {
    dispatch(updateEmailCodeStart(values))
    props.setNewEmail(values.email)
  };

  useEffect(()=> {
    if(!skipRender && !updateEmailCode.loading && Object.keys(updateEmailCode.data).length > 0)
    {
      props.closeChangeEmailAddressModal();
    }
    setSkipRender(false);
  }, [updateEmailCode])

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.changeEmailAddress}
        onHide={props.closeChangeEmailAddressModal}
      >
        <Modal.Body>
          <Button
            onClick={props.closeChangeEmailAddressModal}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
          <div className="settlement-top-sec">
            <h3>{t("heading")}</h3>
          </div>
          <Row className="align-items-center">
            <Col md={12}>
                <div className="bank-account-form-sec">
                <Formik
                    initialValues={{
                      email: props.profile.data.email,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <FORM className="bank-account-form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("user_email.label")}</Form.Label>
                          <Field
                            name="email"
                            type="email"
                            className="form-control"
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <div className="request-payout-payment-method-btn-sec">
                          <Button className="efi-cancel-btn" onClick={()=> setFieldValue("email", "")}>{t("reset")}</Button>
                          <Button className="efi-auth-btn" disabled={updateEmailCode.buttonDisable || values.email==props.profile.data.email} type="submit">{updateEmailCode.buttonDisable ? <ButtonLoader/>: t("submit_btn.text")}</Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
            </Col>
          </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeEmailAddressModal;
