import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("lang", 'en')
        navigate('/login')
    }

    return { logout };

}

export default useLogout;