import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { updateProfileStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const ChangeUserNameModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("change_username");
  const updateProfile = useSelector((state) => state.admin.updateProfile);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("user_name.required")).test('len', t("user_name.invalid"),
     val => val && val.toString().length < 255),
  });

  const handleSubmit = (values) => {
    dispatch(updateProfileStart(values))
  };

  useEffect(()=> {
    if(!skipRender && !updateProfile.loading && Object.keys(updateProfile.data).length > 0)
      props.closeChangeUserNameModal();
    setSkipRender(false);
  }, [updateProfile])

  return (
    <>
      <Modal
        className="modal-dialog-center payment-link-created-modal"
        size="md"
        centered
        show={props.changeUserName}
        onHide={props.closeChangeUserNameModal}
      >
        <Modal.Body>
          <Button
            onClick={props.closeChangeUserNameModal}
            className="modal-close"
          >
            <Image
              className="close-icon"
              src={window.location.origin + "/img/close-modal-icon.svg"}
            />
          </Button>
          <div className="add-bank-account-modal-sec">
            <div className="settlement-top-sec">
              <h3>{t("heading")}</h3>
            </div>
            <Row className="align-items-center">
              <Col md={12}>
                <div className="bank-account-form-sec">
                  <Formik
                    initialValues={{
                      name: props.profile.data.name,
                      email: props.profile.data.email,
                      mobile: props.profile.data.mobile,
                      country_code: props.profile.data.country_code,
                      citizenship: props.profile.data.citizenship,
                      residence: props.profile.data.residence,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ values, setFieldValue }) => (
                      <FORM className="bank-account-form">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>{t("user_name.label")}</Form.Label>
                          <Field
                            name="name"
                            className="form-control"
                            value={values.name}
                            type="text"
                            autoFocus={true}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <div className="request-payout-payment-method-btn-sec">
                          <Button className="efi-cancel-btn" onClick={()=> setFieldValue("name", "")}>{t("reset")}</Button>
                          <Button className="efi-auth-btn" disabled={updateProfile.buttonDisable || values.name==props.profile.data.name} type="submit">{updateProfile.buttonDisable ? <ButtonLoader/>: t("save_changes.text")}</Button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangeUserNameModal;
