import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  categoryBulkActionFailure,
  categoryBulkActionSuccess,
  categoryDeleteFailure,
  categoryDeleteSuccess,
  categoryListFailure,
  categoryListSuccess,
  categorySaveFailure,
  categorySaveSuccess,
  categoryStatusFailure,
  categoryStatusSuccess,
  categoryViewFailure,
  categoryViewSuccess,
  subCategoryBulkActionFailure,
  subCategoryBulkActionSuccess,
  subCategoryDeleteFailure,
  subCategoryDeleteSuccess,
  subCategoryListFailure,
  subCategoryListSuccess,
  subCategoryStatusSuccess,
  subCategoryViewFailure,
  subCategoryViewSuccess
} from "../slices/CategorySlice";


function* categoryListAPI(action) {
  yield getCommonSaga({
    apiUrl: "categories",
    payload: action.payload,
    successNotify: false,
    success: categoryListSuccess,
    failure: categoryListFailure,
  });
}

function* categoryStatusAPI(action) {
  const categoriesListData = yield select(state => state.category.categoryList.data);
  const categoryViewData = yield select(state => state.category.categoryView.data);
  yield getCommonSaga({
    apiUrl: "categories/status",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(categoryStatusSuccess(data));
      if (Object.keys(categoriesListData).length > 0)
        store.dispatch(categoryListSuccess({
          ...categoriesListData,
          categories: categoriesListData.categories.map((category) => category.category_id === action.payload.category_id ? data.category : category),
        }));
      if (Object.keys(categoryViewData).length > 0 && categoryViewData.category.category_id === action.payload.category_id)
        store.dispatch(categoryViewSuccess(data));
    },
    failure: categoryStatusFailure,
  })
}

function* categoryBulkActionAPI(action) {
  const categoriesListData = yield select(state => state.category.categoryList.data);
  const { categoryIds, ...payload } = action.payload;
  yield getCommonSaga({
    apiUrl: "categories/bulk_action",
    payload: payload,
    successCallback: true,
    success: data => {
      store.dispatch(categoryBulkActionSuccess({ data: data }));
      if (payload.action_type === 3)
        store.dispatch(categoryListSuccess(data))
      else
        store.dispatch(categoryListSuccess({
          ...categoriesListData,
          categories: categoriesListData.categories.map(category => categoryIds.includes(category.category_id) ?
            { ...category, status: payload.action_type === 1 ? 1 : 0 }
            : category
          )
        }))
    },
    failure: categoryBulkActionFailure,
  })
}

function* categoryDeleteAPI(action) {
  const categoriesListData = yield select(state => state.category.categoryList.data);
  yield getCommonSaga({
    apiUrl: "categories/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(categoryDeleteSuccess({ data: data }));
      if (Object.keys(categoriesListData).length > 0)
        store.dispatch(categoryListSuccess({
          categories: [
            ...categoriesListData.categories.filter(cat => cat.category_id !== action.payload.category_id),
            ...data.categories,
          ],
          total_categories: data.total_categories,
        }))
    },
    failure: categoryDeleteFailure
  })
}

function* categoryViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "categories/show",
    payload: action.payload,
    successNotify: false,
    success: categoryViewSuccess,
    failure: categoryViewFailure,
  });
}

function* categorySaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "categories/store",
    payload: action.payload,
    successNotify: false,
    success: categorySaveSuccess,
    failure: categorySaveFailure,
  });
}


function* subCategoryListAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_categories",
    payload: action.payload,
    successNotify: false,
    success: subCategoryListSuccess,
    failure: subCategoryListFailure,
  });
}

function* subCategoryStatusAPI(action) {
  const subCategoriesListData = yield select(state => state.category.subCategoryList.data);
  const subCategoryViewData = yield select(state => state.category.subCategoryView.data);
  yield getCommonSaga({
    apiUrl: "sub_categories/status",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(subCategoryStatusSuccess(data));
      if (Object.keys(subCategoriesListData).length > 0)
        store.dispatch(subCategoryListSuccess({
          ...subCategoriesListData,
          sub_categories: subCategoriesListData.sub_categories.map((subCategory) => subCategory.sub_category_id === action.payload.sub_category_id ? data.sub_category : subCategory),
        }));
      if (Object.keys(subCategoryViewData).length > 0 && subCategoryViewData.sub_category.sub_category_id === action.payload.sub_category_id)
        store.dispatch(subCategoryViewSuccess(data));
    },
    failure: categoryStatusFailure,
  })
}

function* subCategoryBulkActionAPI(action) {
  const subCategoriesListData = yield select(state => state.category.subCategoryList.data);
  const { subCategoryIds, ...payload } = action.payload;
  yield getCommonSaga({
    apiUrl: "sub_categories/bulk_action",
    payload: payload,
    successCallback: true,
    success: data => {
      store.dispatch(subCategoryBulkActionSuccess({ data: data }));
      if (payload.action_type === 3)
        store.dispatch(subCategoryListSuccess(data))
      else
        store.dispatch(subCategoryListSuccess({
          ...subCategoriesListData,
          sub_categories: subCategoriesListData.sub_categories.map(subCategory => subCategoryIds.includes(subCategory.sub_category_id) ?
            { ...subCategory, status: payload.action_type === 1 ? 1 : 0 }
            : subCategory
          )
        }))
    },
    failure: subCategoryBulkActionFailure,
  })
}

function* subCategoryDeleteAPI(action) {
  const subCategoriesListData = yield select(state => state.category.swubCategoryList.data);
  yield getCommonSaga({
    apiUrl: "sub_categories/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(subCategoryDeleteSuccess({ data: data }));
      if (Object.keys(subCategoriesListData).length > 0)
        store.dispatch(subCategoryListSuccess({
          sub_categories: [
            ...subCategoriesListData.sub_categories.filter(subCat => subCat.sub_category_id !== action.payload.sub_category_id),
            ...data.sub_categories,
          ],
          total_sub_categories: data.total_sub_categories,
        }))
    },
    failure: subCategoryDeleteFailure
  })
}

function* subCategoryViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_categories/show",
    payload: action.payload,
    successNotify: false,
    success: subCategoryViewSuccess,
    failure: subCategoryViewFailure,
  });
}

function* subCategorySaveAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_categories/store",
    payload: action.payload,
    successNotify: false,
    success: categorySaveSuccess,
    failure: categorySaveFailure,
  });
}


export default function* CategorySaga() {
  yield all([
    yield takeLatest('category/categoryListStart', categoryListAPI),
    yield takeLatest('category/categoryStatusStart', categoryStatusAPI),
    yield takeLatest('category/categoryBulkActionStart', categoryBulkActionAPI),
    yield takeLatest('category/categoryDeleteStart', categoryDeleteAPI),
    yield takeLatest('category/categoryViewStart', categoryViewAPI),
    yield takeLatest('category/categorySaveStart', categorySaveAPI),

    yield takeLatest('category/subCategoryListStart', subCategoryListAPI),
    yield takeLatest('category/subCategoryStatusStart', subCategoryStatusAPI),
    yield takeLatest('category/subCategoryBulkActionStart', subCategoryBulkActionAPI),
    yield takeLatest('category/subCategoryDeleteStart', subCategoryDeleteAPI),
    yield takeLatest('category/subCategoryViewStart', subCategoryViewAPI)
  ]);
}

