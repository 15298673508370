import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";

const LandingPageIndex = (props) => {

  return (
    <>
      
    </>
  );
};

export default LandingPageIndex;
