import React from 'react'
import Skeleton from 'react-loading-skeleton'

const DashboardLoader = () => {
  return (
    <div className="dashboard-sec">
        <div className="dashboard-header-sec">
          <div className="dashboard-box">
            {[...Array(8)].map((i, key) => <Skeleton key={key} height={130} />)}
          </div>
        </div>
        <div className="dashboard-body-sec">
          <div className="dashboard-transaction-box">
            <div className="dashboard-transaction-card">
              <div className="dashboard-transaction-top-sec">
                <div className="dashboard-transaction-left-sec">
                  <h5>Transaction Overview</h5>
                </div>
              </div>
              <div className="dasboard-transaction-graph-sec">
                <Skeleton height={250} />
              </div>
            </div>
          </div>
          <div className="dashboard-transaction-multiple-graph-sec">
            <div className="dashboard-transaction-multiple-card">
              <div className="dashboard-transaction-top-multiple-sec">
                <div className="dashboard-transaction-multiple-left-sec">
                  <h5>Transaction Analytics</h5>
                </div>
              </div>
              <div className="dashboard-transaction-analytics-graph-sec">
                <Skeleton height={250} />
              </div>
            </div>
            <div className="dashboard-transaction-multiple-card">
              <div className="dashboard-transaction-top-multiple-sec">
                <div className="dashboard-transaction-multiple-left-sec">
                  <h5>Transaction Traffic</h5>
                </div>
              </div>
              <div className="dashboard-transaction-analytics-graph-sec">
                <Skeleton height={300} />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default DashboardLoader