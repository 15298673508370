import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    categoryList: getCommonInitialState(),
    categoryStatus: getCommonInitialState(),
    categoryBulkAction: getCommonInitialState(),
    categoryDelete: getCommonInitialState(),
    categoryView: getCommonInitialState(),
    categorySave: getCommonInitialState(),

    subCategoryList: getCommonInitialState(),
    subCategoryStatus: getCommonInitialState(),
    subCategoryBulkAction: getCommonInitialState(),
    subCategoryDelete: getCommonInitialState(),
    subCategoryView: getCommonInitialState(),
    subCategorySave: getCommonInitialState(),
};

const CategorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {

        //Category List
        categoryListStart: (state) => { state.categoryList = getCommonStart() },
        categoryListSuccess: (state, action) => { state.categoryList = getCommonSuccess(action.payload) },
        categoryListFailure: (state, action) => { state.categoryList = getCommonFailure(action.payload) },

        //Change Category status
        categoryStatusStart: (state, action) => { state.categoryStatus = getCommonStart(action.payload) },
        categoryStatusSuccess: (state, action) => { state.categoryStatus = getCommonSuccess(action.payload) },
        categoryStatusFailure: (state, action) => { state.categoryStatus = getCommonFailure(action.payload) },

        // Bulk Action
        categoryBulkActionStart: (state) => { state.categoryBulkAction = getCommonStart() },
        categoryBulkActionSuccess: (state, action) => { state.categoryBulkAction = getCommonSuccess(action.payload) },
        categoryBulkActionFailure: (state, action) => { state.categoryBulkAction = getCommonFailure(action.payload) },

        // Delete category
        categoryDeleteStart: (state, action) => { state.categoryDelete = getCommonStart(action.payload) },
        categoryDeleteSuccess: (state, action) => { state.categoryDelete = getCommonSuccess(action.payload) },
        categoryDeleteFailure: (state, action) => { state.categoryDelete = getCommonFailure(action.payload) },

        // View Category
        categoryViewStart: (state) => { state.categoryView = getCommonStart() },
        categoryViewSuccess: (state, action) => { state.categoryView = getCommonSuccess(action.payload) },
        categoryViewFailure: (state, action) => { state.categoryView = getCommonFailure(action.payload) },

        // Save Category
        categorySaveStart: (state) => { state.categorySave = getCommonStart() },
        categorySaveSuccess: (state, action) => { state.categorySave = getCommonSuccess(action.payload) },
        categorySaveFailure: (state, action) => { state.categorySave = getCommonFailure(action.payload) },

        // Sub Category List
        subCategoryListStart: (state) => { state.subCategoryList = getCommonStart() },
        subCategoryListSuccess: (state, action) => { state.subCategoryList = getCommonSuccess(action.payload) },
        subCategoryListFailure: (state, action) => { state.subCategoryList = getCommonFailure(action.payload) },

        // Sub Category Status
        subCategoryStatusStart: (state, action) => { state.subCategoryStatus = getCommonStart(action.payload) },
        subCategoryStatusSuccess: (state, action) => { state.subCategoryStatus = getCommonSuccess(action.payload) },
        subCategoryStatusFailure: (state, action) => { state.subCategoryStatus = getCommonFailure(action.payload) },

        // Sub Category Bulk action
        subCategoryBulkActionStart: (state) => { state.subCategoryBulkAction = getCommonStart() },
        subCategoryBulkActionSuccess: (state, action) => { state.subCategoryBulkAction = getCommonSuccess(action.payload) },
        subCategoryBulkActionFailure: (state, action) => { state.subCategoryBulkAction = getCommonFailure(action.payload) },

        // Delete sub category
        subCategoryDeleteStart: (state, action) => { state.subCategoryDelete = getCommonStart(action.payload) },
        subCategoryDeleteSuccess: (state, action) => { state.subCategoryDelete = getCommonSuccess(action.payload) },
        subCategoryDeleteFailure: (state, action) => { state.subCategoryDelete = getCommonFailure(action.payload) },

        // View Sub Category
        subCategoryViewStart: (state) => { state.subCategoryView = getCommonStart() },
        subCategoryViewSuccess: (state, action) => { state.subCategoryView = getCommonSuccess(action.payload) },
        subCategoryViewFailure: (state, action) => { state.subCategoryView = getCommonFailure(action.payload) },

        // Save Sub Category
        subCategorySaveStart: (state) => { state.subCategorySave = getCommonStart() },
        subCategorySaveSuccess: (state, action) => { state.subCategorySave = getCommonSuccess(action.payload) },
        subCategorySaveFailure: (state, action) => { state.subCategorySave = getCommonFailure(action.payload) },

    }
});


export const {
    categoryListStart,
    categoryListSuccess,
    categoryListFailure,
    categoryStatusStart,
    categoryStatusSuccess,
    categoryStatusFailure,
    categoryBulkActionStart,
    categoryBulkActionSuccess,
    categoryBulkActionFailure,
    categoryDeleteStart,
    categoryDeleteSuccess,
    categoryDeleteFailure,
    categoryViewStart,
    categoryViewSuccess,
    categoryViewFailure,
    categorySaveStart,
    categorySaveSuccess,
    categorySaveFailure,
    subCategoryListStart,
    subCategoryListSuccess,
    subCategoryListFailure,
    subCategoryStatusStart,
    subCategoryStatusSuccess,
    subCategoryStatusFailure,
    subCategoryBulkActionStart,
    subCategoryBulkActionSuccess,
    subCategoryBulkActionFailure,
    subCategoryDeleteStart,
    subCategoryDeleteSuccess,
    subCategoryDeleteFailure,
    subCategoryViewStart,
    subCategoryViewSuccess,
    subCategoryViewFailure,
    subCategorySaveStart,
    subCategorySaveSuccess,
    subCategorySaveFailure,

} = CategorySlice.actions;

export default CategorySlice.reducer;