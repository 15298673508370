import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Form,
  InputGroup,
} from "react-bootstrap";
import Select from "react-select";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  transactionCallbackStart,
  transactionExportStart,
  transactionListStart,
  transactionRefundStart,
  transactionViewStart,
} from "../../store/slices/PaymentSlice";
import Skeleton from "react-loading-skeleton";
import {
  getErrorNotificationMessage,
} from "../Helper/NotificationMessage";
import Pagination from "../Helper/Pagination";
import { Field, Form as FORM, Formik } from "formik";
import ViewTransactionModal from "./ViewTransactionModal";
import TransactionStatusUpdateModal from "./TransactionStatusUpdateModal";
import NoDataFound from "../Helper/NoDataFound";
import { useTranslation } from "react-multi-lang";
import DatePicker from "react-multi-date-picker";
import { ButtonLoader } from "../Helper/Loader";
import { set } from "react-global-configuration";

const TransactionsIndex = (props) => {
  const t = useTranslation("transactions");
  const [modalShow, setModalShow] = React.useState(false);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878787",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#3D9F99",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878787",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    // { value: "price_hl", label: "Price High to Low" },
    // { value: "price_lh", label: "Price Low to High" },
  ];

  const payment_type_options = [
    { value: "card", label: "Card" },
    { value: "upi", label: "UPI" },
    { value: "crypto", label: "Crypto" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const profile = useSelector((state) => state.admin.profile);
  const transactionList = useSelector((state) => state.payment.transactionList);
  const transactionExport = useSelector((state) => state.payment.transactionExport);
  const transactionRefund = useSelector((state) => state.payment.transactionRefund);
  const transactionCallback = useSelector((state) => state.payment.transactionCallback);
  const transactionView = useSelector((state) => state.payment.transactionView);
  const [skipRender, setSkipRender] = useState(true);
  const [filterStatus, setFilterStatus] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [value, setValue] = useState();
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filter, setFilter] = useState({
    sort_by: searchParams.get("sort_by") ? searchParams.get("sort_by") : "",
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    type: searchParams.get("type") ? searchParams.get("type") : "",
    payment_type: "",
    // invoice_links: 1,
  });
  const [acceptPayoutModal, setAcceptPayoutPaymentModal] = useState(false);
  const [dateValues, setDateValues] = useState({
    from_date: "",
    to_date: "",
  });
  const formRef = useRef(null);
  const calenderRef = useRef(null);
  const [close, setClose] = useState(false);

  const closeAcceptPayoutPaymentModal = () => {
    setAcceptPayoutPaymentModal(false);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        transactionListStart({
          ...filter,
          // paid_as: 2,
          skip: 12 * (page - 1),
          take: 12,
        })
      );
    }
  }, [profile, page, filter]);

  // useEffect(() => {
  //   if (!skipRender) {
  //     setPage(searchParams.get("page") ? searchParams.get("page") : 1);
  //     setFilter({
  //       search_key: searchParams.get("search_key")
  //         ? searchParams.get("search_key")
  //         : "",
  //       status: searchParams.get("status") ? searchParams.get("status") : "",
  //     });
  //   }
  //   setSkipRender(false);
  // }, [
  //   searchParams.get("page"),
  //   searchParams.get("search_key"),
  //   searchParams.get("status"),
  // ]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("payment_type")
      ? `payment_type=${searchParams.get("payment_type")}&`
      : "";
    navigate(`/transactions?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    let option = options.find(
      (option) => option.value == searchParams.get("sort_by")
    );
    if (option) {
      setFilterStatus(option);
    }
  }, [searchParams.get("sort_by")]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  // useEffect(() => {
  //   let payment_type_option = payment_type_options.find(
  //     (option) => option.value == searchParams.get("payment_type")
  //   );
  //   if (payment_type_option) {
  //     setPaymentType(payment_type_option);
  //   }
  // }, [searchParams.get("payment_type")]);

  const getStatusClass = (status) => {
    switch (status) {
      case "Initiated":
        return "payment-initiated";
      case "Inprogress":
        return "payment-onhold";
      case "Success":
        return "payment-paid";
      case "Failed":
        return "payment-rejected";
      case "Cancelled":
        return "payment-cancelled";
      case "Chargeback":
        return "payment-failed";
      case "Refunded":
        return "payment-initiated";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Initiated":
        return "#5C9EEB";
      case "Inprogress":
        return "#FFCE22";
      case "Success":
        return "#197E23";
      case "Failed":
        return "#ff0000";
      case "Cancelled":
        return "#FF8A00";
      case "Chargeback":
        return "#FF6A6A";
      case "Refunded":
        return "#5C9EEB";
      default:
        return "#5C9EEB";
    }
  };

  const handleExport = () => {
    dispatch(
      transactionExportStart({
        ...filter,
        is_central: 1,
        from_date: dateValues.from_date,
        to_date: dateValues.to_date,
      })
    );
  };

  const handleCallback = (reference_id) => {
    dispatch(
      transactionCallbackStart({
        reference_id: reference_id,
      })
    );
  };

  const handleDateChange = (values) => {
    if (values) {
      setValue(values);
      formRef?.current?.setFieldValue(
        "from_date",
        values && values.length > 0
          ? JSON.stringify(new Date(values[0])).slice(1, 11)
          : ""
      );
      formRef?.current?.setFieldValue(
        "to_date",
        values && values.length > 1
          ? JSON.stringify(new Date(values[1])).slice(1, 11)
          : ""
      );
      setDateValues({
        ...dateValues,
        from_date:
          values.length > 0
            ? JSON.stringify(new Date(values[0])).slice(1, 11)
            : "",
        to_date:
          values.length > 1
            ? JSON.stringify(new Date(values[1])).slice(1, 11)
            : "",
      });
    } else {
      setDateValues({
        from_date: "",
        to_date: "",
      });
      formRef?.current?.setFieldValue("from_date", "");
      formRef?.current?.setFieldValue("to_date", "");
      calenderRef.current.closeCalendar();
      dispatch(
        transactionListStart({
          ...filter,
          skip: 12 * (page - 1),
          take: 12,
          from_date: "",
          to_date: "",
        })
      );
    }
  };

  useEffect(() => {
    if (!skipRender && !transactionView.loading && Object.keys(transactionView.data).length > 0) {
      setModalShow(transactionView.data.response);
    }
    setSkipRender(false);
  }, [transactionView])

  return (
    <>
      <div className="payment-link-sec">
        <div className="payment-link-box">
          <div className="settlement-body-sec">
            <div className="payment-link-header-sec">
              <h3>{t("invoice_title")}</h3>
            </div>
            <div className="transactions-list-filter-sec">

              <div className="header-search">
                <Formik
                  initialValues={{
                    search_key: searchParams.get("search_key")
                      ? searchParams.get("search_key")
                      : "",
                    sort_by: searchParams.get("sort_by")
                      ? searchParams.get("sort_by")
                      : filter.sort_by,
                    from_date: "",
                    to_date: "",
                  }}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      search_key: val.search_key,
                    });
                    navigate(
                      searchParams.get("sort_by")
                        ? `/transactions?search_key=${val.search_key
                        }&sort_by=${searchParams.get("sort_by")}`
                        : `/transactions?search_key=${val.search_key}`
                    );
                  }}
                  innerRef={formRef}
                >
                  {({ values, setFieldValue, resetForm }) => (
                    <FORM className="form" style={{ display: "flex", gap: "1em" }}>
                      <Form.Group className="d-flex date-sec">
                        <DatePicker
                          value={value}
                          style={{ padding: "1em" }}
                          placeholder="Select Date Range"
                          maxDate={new Date()}
                          onChange={handleDateChange}
                          range
                          numberOfMonths={2}
                          ref={calenderRef}
                        >
                          <div
                            className="d-flex justify-content-end"
                            style={{ padding: "1em" }}
                          >
                            <Button
                              className="efi-datePicker-btn-cancel"
                              onClick={() => {
                                calenderRef.current.closeCalendar();
                              }}
                            >
                              {t("close")}
                            </Button>
                            <Button
                              className="efi-datePicker-btn"
                              onClick={() => {
                                if (values.from_date || values.to_date) {
                                  setFilter({
                                    ...filter,
                                    skip: 12 * (page - 1),
                                    take: 12,
                                    from_date: dateValues.from_date,
                                    to_date: dateValues.to_date,
                                  });
                                  calenderRef.current.closeCalendar();
                                  setClose(true);
                                } else
                                  getErrorNotificationMessage(
                                    "Please select date"
                                  );
                              }}
                            >
                              {t("apply")}
                            </Button>
                          </div>
                        </DatePicker>

                        {close ? (
                          <>
                            <button
                              type="button"
                              className="search-btn date-cancel"
                              onClick={() => {
                                setDateValues({
                                  from_date: "",
                                  to_date: "",
                                });
                                setValue();
                                setClose(false);
                                setFilter({
                                  ...filter,
                                  from_date: "",
                                  to_date: "",
                                });
                              }}
                            >
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                class="css-tj5bde-Svg"
                              >
                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                              </svg>
                            </button>
                          </>
                        ) : null}
                      </Form.Group>
                      <InputGroup className="mb-0">
                        <Field
                          placeholder={t("search_placeholder")}
                          type="text"
                          className="form-control"
                          name="search_key"
                        />
                        <InputGroup.Text id="basic-addon2">
                          {searchParams.get("search_key") && (
                            <button
                              type="button"
                              className="search-btn"
                              onClick={() => {
                                setFieldValue("search_key", "");
                                setFilter({
                                  ...filter,
                                  search_key: "",
                                });
                                navigate(
                                  searchParams.get("sort_by")
                                    ? `/transactions?sort_by=${searchParams.get(
                                      "sort_by"
                                    )}`
                                    : `/transactions`
                                );
                              }}
                            >
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#3E4351"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                              </svg> */}
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                class="css-tj5bde-Svg"
                              >
                                <path fill="#878787" d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                              </svg>
                            </button>
                          )}
                        </InputGroup.Text>
                        <InputGroup.Text id="basic-addon2">
                          <button className="search-btn" type="submit">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#878787"
                              x="0"
                              y="0"
                              enableBackground="new 0 0 513.749 513.749"
                              version="1.1"
                              viewBox="0 0 513.749 513.749"
                              xmlSpace="preserve"
                            >
                              <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                            </svg> */}
                            <svg  width="24"
                              height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.3199 14.9056C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2Z" fill="#878787" />
                            </svg>
                          </button>
                        </InputGroup.Text>
                      </InputGroup>
                    </FORM>
                  )}
                </Formik>
              </div>
              <div className="top-header-action-filter-wrap">
                <Select
                  options={options}
                  styles={customStyles}
                  // menuIsOpen={true}
                  isSearchable={false}
                  isClearable
                  value={filterStatus || null}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setFilter({
                        ...filter,
                        sort_by: selectedOption.value,
                      });
                      setFilterStatus(selectedOption);
                      navigate(
                        filter.search_key
                          ? `/transactions?search_key=${filter.search_key}&sort_by=${selectedOption.value}`
                          : `/transactions?sort_by=${selectedOption.value}`
                      );
                    } else {
                      setFilter({
                        ...filter,
                        sort_by: "",
                      });
                      navigate(
                        filter.search_key
                          ? `/transactions?search_key=${filter.search_key}`
                          : "/transactions"
                      );
                      setFilterStatus(null);
                    }
                  }}
                  placeholder={
                    <div className="placeholder-flex">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#878787"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M14 24a1 1 0 01-.6-.2l-4-3A1 1 0 019 20v-5.62L1.984 6.487A3.9 3.9 0 014.9 0h14.2a3.9 3.9 0 012.913 6.488L15 14.38V23a1 1 0 01-1 1zm-3-4.5l2 1.5v-7a1 1 0 01.253-.664l7.268-8.177A1.9 1.9 0 0019.1 2H4.9a1.9 1.9 0 00-1.421 3.158l7.269 8.178A1 1 0 0111 14z"></path>
                      </svg> */}
                      <svg width="20"
                        height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V6.17157C22 6.96722 21.6839 7.73028 21.1213 8.29289L15.2929 14.1213C15.1054 14.3089 15 14.5632 15 14.8284V17.1716C15 17.9672 14.6839 18.7303 14.1213 19.2929L11.9193 21.4949C10.842 22.5722 9 21.8092 9 20.2857V14.8284C9 14.5632 8.89464 14.3089 8.70711 14.1213L2.87868 8.29289C2.31607 7.73028 2 6.96722 2 6.17157V5Z" fill="#878787" />
                      </svg>
                      {t("filter_placeholder")}
                    </div>
                  }
                />
                <Button
                  type="button"
                  className=""
                  onClick={handleExport}
                  disabled={
                    transactionExport.buttonDisable ||
                    !Object.keys(transactionList.data).length > 0
                  }
                >
                  {/* <svg
                   fill="#878787"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    imageRendering="optimizeQuality"
                    shapeRendering="geometricPrecision"
                    textRendering="geometricPrecision"
                    viewBox="0 0 43.349 43.349"
                  >
                    <g>
                      <g>
                        <path d="M35.115 26.333v8.303c0 3.582-2.924 6.506-6.507 6.506H6.506C2.923 41.142 0 38.218 0 34.635V12.534C0 8.95 2.923 6.027 6.506 6.027H21.65v4.607H6.506a1.91 1.91 0 00-1.899 1.9v22.101a1.91 1.91 0 001.9 1.9h22.101a1.91 1.91 0 001.9-1.9v-8.302z"></path>
                        <path d="M29.532 2.207L43.35 13.253 29.532 24.298v-6.645c-6.885.486-12.635 4.715-14.144 11.45-1.498-9.233 4.926-18.667 14.144-20.25V2.207z"></path>
                      </g>
                    </g>
                  </svg> */}
                  <svg  width="24"
                    height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.25 12.33C17.25 12.74 16.91 13.08 16.5 13.08C16.09 13.08 15.75 12.74 15.75 12.33V9.31L8.03 17.03C7.88 17.18 7.69 17.25 7.5 17.25C7.31 17.25 7.12 17.18 6.97 17.03C6.68 16.74 6.68 16.26 6.97 15.97L14.69 8.25H11.67C11.26 8.25 10.92 7.91 10.92 7.5C10.92 7.09 11.26 6.75 11.67 6.75H16.5C16.91 6.75 17.25 7.09 17.25 7.5V12.33Z" fill="#878787" />
                  </svg>
                </Button>
              </div>
            </div>
          </div>
          <div className="recent-transaction-table-sec table-responsive">
            {transactionList.loading ? (
              [...Array(3)].map((i, key) => <Skeleton key={key} height={50} />)
            ) : Object.keys(transactionList.data).length > 0 && Object.keys(transactionList.data.response).length > 0 ? (
              <React.Fragment>
                <Table hover>
                  <thead>
                    <tr>
                      <th>{t("sl_no")}</th>
                      <th>{t("reference_id")}</th>
                      <th>{t("order_id")}</th>
                      <th>{t("total")}</th>
                      <th>{t("mode")}</th>
                      <th>{t("created_date")}</th>
                      <th className="text-center">{t("status")}</th>
                      <th>{t("actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList.data.response.transactions.length > 0 ? (
                      transactionList.data.response.transactions.map(
                        (transaction, i) => (
                          <tr key={i}>
                            <td>{(12 * (page - 1)) + (i + 1)}</td>
                            <td>{transaction.reference_id}</td>
                            <td>{transaction.order_id}</td>
                            <td>{transaction.amount} {transaction.currency_symbol}</td>
                            <td>{transaction.mode}</td>
                            <td>{transaction.created}</td>
                            <td>
                              <div
                                className={getStatusClass(transaction.status)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill={getStatusColor(transaction.status)}
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                                </svg>
                                {transaction.status}
                              </div>
                            </td>
                            <td>
                              <div className="payment-link-dropdown">
                                {((transactionRefund.loading && transactionRefund.data.reference_id == transaction.reference_id) || (transactionCallback.loading && transactionCallback.data.reference_id == transaction.reference_id) || (transactionView.loading && transactionView.data.reference_id == transaction.reference_id)) ? <ButtonLoader /> : <Dropdown>
                                  <div className="payment-link-icon-sec">
                                    <Dropdown.Toggle id="dropdown-basic">
                                      {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="35"
                                        height="35"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                      >
                                        <clipPath id="clip0_17_4644">
                                          <path d="M0 0h24v24H0z"></path>
                                        </clipPath>
                                        <g clipPath="url(#clip0_17_4644)">
                                          <path
                                            fill="#878787"
                                            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                                          ></path>
                                        </g>
                                      </svg> */}
                                      <svg width="30"
                                        height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782ZM11.0049 8.005C11.0049 8.55728 11.4526 9.005 12.0049 9.005C12.5572 9.005 13.0049 8.55728 13.0049 8.005V7.995C13.0049 7.44271 12.5572 6.995 12.0049 6.995C11.4526 6.995 11.0049 7.44271 11.0049 7.995V8.005ZM12.0049 13.005C11.4526 13.005 11.0049 12.5573 11.0049 12.005V11.995C11.0049 11.4427 11.4526 10.995 12.0049 10.995C12.5572 10.995 13.0049 11.4427 13.0049 11.995V12.005C13.0049 12.5573 12.5572 13.005 12.0049 13.005ZM12.0049 17.005C11.4526 17.005 11.0049 16.5573 11.0049 16.005V15.995C11.0049 15.4427 11.4526 14.995 12.0049 14.995C12.5572 14.995 13.0049 15.4427 13.0049 15.995V16.005C13.0049 16.5573 12.5572 17.005 12.0049 17.005Z" fill="#878787" />
                                      </svg>
                                    </Dropdown.Toggle>
                                  </div>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => dispatch(
                                        transactionViewStart({
                                          reference_id: transaction.reference_id,
                                        })
                                      )}
                                    >
                                      <span>{t("view_btn")}</span>
                                    </Dropdown.Item>
                                    {transaction.status == "Success" ||
                                      transaction.status == "Failed" ? (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleCallback(transaction.reference_id)
                                        }
                                      >
                                        <span>{t("callback_btn")}</span>
                                      </Dropdown.Item>
                                    ) : null}
                                    {transaction.status == "Success" ? (
                                      <Dropdown.Item
                                        onClick={() =>
                                          dispatch(transactionRefundStart({
                                            reference_id: transaction.reference_id,
                                          }))
                                        }
                                      >
                                        <span>{t("refund_btn")}</span>
                                      </Dropdown.Item>
                                    ) : null}
                                  </Dropdown.Menu>
                                </Dropdown>}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          <NoDataFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {transactionList.data.response.total_transactions > 12 ? (
                  <div className="new-billings-pagination-sec">
                    <Pagination
                      page={page}
                      pageCount={Math.ceil(
                        transactionList.data.response.total_transactions / 12
                      )}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
            <div></div>
          </div>
        </div>
      </div>
      <ViewTransactionModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {acceptPayoutModal && (
        <TransactionStatusUpdateModal
          acceptPayoutModal={acceptPayoutModal}
          closeAcceptPayoutPaymentModal={closeAcceptPayoutPaymentModal}
          setAcceptPayoutPaymentModal={setAcceptPayoutPaymentModal}
        />
      )}
    </>
  );
};

export default TransactionsIndex;
